@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.breadcrumb {
  display: flex;
  align-items: center;
  padding: 13.5px 32px;
  overflow: hidden;
  background: $bg;

  @include sp {
    display: none;
  }

  &__item {
    font: normal 14px/1.5 $font-ja;
    letter-spacing: 1.4px;

    &:not(:first-child) {
      position: relative;
      padding-left: 40px;

      &::before {
        position: absolute;
        top: 50%;
        left: 12px;
        width: 16px;
        height: 16px;
        content: "";
        background-color: #e3e3e3;
        mask: url("../img/icon_arrow.svg") no-repeat center center / contain;
        transform: translateY(-50%);
      }
    }

    a {
      color: $gray;
      white-space: nowrap;
    }

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
}
