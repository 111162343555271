@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.mv {
  display: grid;

  @include pc {
    grid-template-columns: 70% auto;
  }

  &__inner {
    padding: 73px 32px 32px 48px;
    background: $bg;

    @include sp {
      padding: 72px 30px 36px 24px;
    }
  }

  &__title {
    &._en {
      font: 500 64px/64px $font-en;
      letter-spacing: 2.8px;

      @include sp {
        font: bold 30px/1 $font-en;
        letter-spacing: 1.2px;
      }
    }

    &._ja {
      margin-top: 12px;
      font: bold 28px/32px $font-ja;
      letter-spacing: 2.8px;

      @include sp {
        margin-top: 6px;
        font: bold 14px/24px $font-ja;
        letter-spacing: 0.7px;
      }
    }
  }

  &__img {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  &__text {
    margin-top: 32px;
    color: #666;

    @include sp {
      margin-top: 12px;
      font: normal 12px/20px $font-ja;
    }
  }

  &__btn {
    @include pc {
      text-align: right;
    }

    @include sp {
      margin-top: 24px;
      text-align: center;
    }
  }
}
