@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.page-sustainability {
  overflow: hidden;
  font: normal 16px/32px $font-ja;
  color: #444;

  @include sp {
    font: normal 12px/20px $font-ja;
  }

  @include pc {
    letter-spacing: 1.6px;
  }

  ._noMargin {
    margin: 0 !important;
  }

  ._spacer {
    margin-top: 24px;

    @include sp {
      margin-top: 12px;
    }
  }

  .mt60 {
    margin-top: 60px !important;

    @include sp {
      margin-top: 32px !important;
    }
  }

  .recruit__sub {
    margin-top: 60px;

    @include sp {
      margin-top: 0;
    }
  }

  .divide {
    padding-top: 60px;
    margin-top: 60px;
    border-top: 1px solid $border;

    @include sp {
      padding-top: 32px;
      margin-top: 32px;
    }
  }

  img {
    display: block;
    margin-top: 24px;

    @include sp {
      margin-top: 12px;
    }
  }

  p {
    &._margin {
      margin-top: 24px;
    }

    a {
      color: $primary;
      text-decoration: underline;
    }
  }

  .heading03 {
    padding-top: 120px;
    margin-top: 120px;
    margin-bottom: 60px;
    font: bold 18px/32px $font-ja;
    color: #222;
    text-align: center;
    letter-spacing: 1.8px;
    border-top: 1px solid $border;

    &._first {
      margin-top: 0;
      border: none;
    }

    @include sp {
      padding-top: 60px;
      margin-top: 60px;
      margin-bottom: 32px;
      font: bold 14px/24px $font-ja;
      letter-spacing: 0.7px;
    }
  }

  .heading04 {
    margin-top: 60px;
    margin-bottom: 24px;
    font: bold 24px/40px $font-ja;
    color: $primary;
    letter-spacing: 1.2px;

    &._black {
      color: #222;
    }

    @include sp {
      margin-top: 32px;
      margin-bottom: 16px;
      font: bold 18px/24px $font-ja;
      letter-spacing: 0.9px;
    }
  }

  .heading05 {
    margin-top: 32px;
    margin-bottom: 12px;
    font: bold 18px/32px $font-ja;
    letter-spacing: 1.8px;

    @include sp {
      margin-top: 24px;
      font: bold 14px/24px $font-ja;
      letter-spacing: 0.7px;
    }
  }

  .heading06 {
    margin-top: 24px;
    margin-bottom: 24px;
    font: bold 16px/32px $font-ja;
    letter-spacing: 1.6px;

    @include sp {
      font: bold 12px/20px $font-ja;
      letter-spacing: 0.6px;
    }
  }

  .annotation {
    padding-left: 1em;
    margin-top: 24px;
    font: normal 14px/24px $font-ja;
    color: #888;
    text-indent: -1em;
    letter-spacing: 0.7px;

    & + .annotation {
      margin-top: 0;
    }

    &._noIndent {
      padding-left: 0;
      text-indent: 0;
    }

    &._asterisk {
      padding-left: 3em;
      text-indent: -3.4em;
    }

    @include sp {
      margin-top: 12px;
      font: normal 10px/16px $font-ja;
      letter-spacing: 0.5px;
    }

    a {
      display: inline;
      color: #888;
      text-decoration: underline;
    }
  }

  .link {
    display: block;
    margin-top: 24px;
    margin-bottom: 24px;
    color: $primary;
    text-decoration: underline;

    @include sp {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  .tcfd__img {
    &Wrap {
      display: flex;
      gap: 32px;
      align-items: center;
      justify-content: center;
      margin-top: 24px;

      @include sp {
        gap: 8px;
        margin-top: 12px;
      }
    }

    &:nth-child(1) {
      max-width: 295px;
    }

    &:nth-child(2) {
      max-width: 247px;
    }

    &:nth-child(3) {
      max-width: 114px;
    }
  }

  .materialityTable {
    overflow-x: auto;

    @include table {
      position: relative;
      width: 100vw;
      margin: 0 calc(50% - 50vw) 0 0;
    }

    table {
      position: relative;
      width: 100%;
      min-width: 600px;
      border: 1px solid $border;

      @include table {
        display: block;
        padding-right: 48px;
        border: none;

        dl {
          &:last-child {
            border-bottom: 1px solid $border;
          }
        }

        &::after {
          position: absolute;
          top: 0;
          left: 551px;
          width: 1px;
          height: 100%;
          content: "";
          background: $border;
        }
      }
    }

    table,
    td {
      color: #666;
      letter-spacing: 0.8px;
      border-collapse: collapse;
    }

    tr {
      &._top {
        td {
          padding: 14px 24px;
          font: bold 16px/32px $font-ja;
          color: #fff;
          text-align: center;
          background: #888;

          @include sp {
            font: bold 10px/16px $font-ja;
          }
        }
      }
    }

    td {
      width: 33.3333%;
      min-height: 80px;
      padding: 16px 24px;
      font: normal 16px/24px $font-ja;
      border: 1px solid $border;

      @include sp {
        padding: 9px 12px;
        font: bold 10px/16px $font-ja;
      }

      p {
        padding-left: 1em;
        text-indent: -1em;
      }

      &._green,
      &._red,
      &._blue {
        font-weight: bold;
        line-height: 2;
      }

      &._green {
        color: #78ad00;
      }

      &._red {
        color: #fa6478;
      }

      &._blue {
        color: #0080c8;
      }
    }
  }

}
.table {
  &Wrap {
    margin-top: 24px;
    margin-bottom: 24px;
    overflow-x: auto;
    letter-spacing: 0.8px;

    @include table {
      position: relative;
      width: 100vw;
      margin: 24px calc(50% - 50vw) 0 0;
    }
  }

  width: 100%;
  min-width: 600px;
  border: 1px solid $border;

  @include table {
    padding-right: 48px;
    border-top: none;
    border-right: none;
    border-bottom: none;

    dl {
      &:last-child {
        border-bottom: 1px solid $border;
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 551px;
      width: 1px;
      height: 100%;
      content: "";
      background: $border;
    }
  }

  &._center {
    dd {
      justify-content: center;
      text-align: center;
    }
  }

  &._row2 {
    dt {
      width: 33%;

      @include sp {
        width: 25%;
      }
    }

    dd {
      width: 67%;

      @include sp {
        width: 75%;
      }
    }
  }

  &._row3 {
    dt,
    dd {
      width: 33.3%;
    }
  }
  &._gpm{
    dl{
      background: $bg;
      &._top{
        background: $primary;
        dd{
          padding: 7px;
          color: #fff;
          justify-content: center;
        }
      }
      dd{
        text-align: left;
        padding: 10px 15px;
        color: $black;
        justify-content: left;
        &._num{
          justify-content: right;
        }
      }
    }
  }

  &._row4 {
    dt,
    dd {
      width: 25%;
    }
  }

  &._row5 {
    dt {
      width: 24.5%;
    }

    dd {
      &:nth-child(2) {
        width: 20.5%;
      }

      &:nth-child(3) {
        width: 20.5%;
      }

      &:nth-child(4) {
        width: 21.5%;
      }

      &:nth-child(5) {
        width: 13%;
      }
    }
  }

  dl {
    display: flex;
    min-height: 80px;

    &:not(:first-child) {
      border-top: 1px solid $border;
    }

    @include sp {
      min-height: 50px;
    }

    &._top {
      min-height: auto;
      background: #888;

      dt,
      dd {
        padding: 9px 24px;
        font: bold 16px/32px $font-ja;
        color: #fff;

        @include sp {
          min-height: 40px;
          padding: 4px 12px;
          font: bold 10px/16px $font-ja;

          &:has(span) {
            padding: 4px 0;
          }
        }
      }
    }

    dt,
    dd {
      display: flex;
      align-items: center;
      padding: 8px 24px;
      color: #666;

      @include sp {
        padding: 8px 12px;
        font: normal 10px/16px $font-ja;
      }
    }

    dt {
      font-weight: bold;

      &._right {
        justify-content: right;
      }
    }

    dd {
      border-left: 1px solid $border;

      &:has(span) {
        flex-direction: column;
      }

      span {
        display: block;
        font: normal 14px/24px $font-ja;
        text-align: center;

        @include sp {
          font: normal 10px/24px $font-ja;
        }
      }
    }
  }
}

.grayBox {
  padding: 24px;
  margin-top: 24px;
  border: 1px solid $border;
  border-radius: 16px;

  @include sp {
    display: grid;
    gap: 8px;
    padding: 16px;
    margin-top: 12px;
  }

  & + & {
    margin-top: 16px;

    @include sp {
      margin-top: 8px;
    }
  }

  &._narrowLs {
    letter-spacing: 0.8px;

    @include sp {
      letter-spacing: 0.6px;
    }
  }

  @include sp {
    border-radius: 8px;
  }

  .title {
    font-weight: bold;
    color: $primary;

    &._black {
      color: #444;
    }
  }

  .text {
    &._bold {
      font-weight: bold;
    }

    span {
      display: block;
      padding-left: 1em;
      text-indent: -1em;

      @include sp {
        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }
  }
}

.workBox {
  padding-bottom: 24px;
  border-bottom: 1px solid $border;

  .title {
    margin-top: 32px;
    font: bold 18px/32px $font-ja;

    @include sp {
      margin-top: 24px;
      font: bold 14px/24px $font-ja;
    }
  }

  .content {
    margin-top: 12px;

    &:has(.img) {
      display: grid;
      gap: 24px;

      @include pc {
        grid-template-columns: repeat(2, 1fr);
      }

      @include sp {
        gap: 12px;
      }
    }

    .img {
      position: relative;

      &::before {
        display: block;
        padding-top: 50%;
        content: "";
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        object-fit: cover;
      }
    }

    .text {
      margin: 0;
    }
  }
}
