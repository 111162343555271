@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.reason {
  &__title {
    padding-top: 60px;
    margin-top: 120px;
    font: bold 18px/32px $font-ja;
    text-align: center;
    border-top: 1px solid $border;

    @include sp {
      padding-top: 32px;
      margin-top: 60px;
      font: bold 14px/24px $font-ja;
    }
  }

  &__subTitle {
    margin-top: 48px;
    font: bold 24px/40px $font-ja;
    color: $primary;

    @include sp {
      margin-top: 32px;
      font: bold 18px/24px $font-ja;
    }
  }

  &__text {
    margin-top: 24px;
    font: normal 16px/32px $font-ja;
    color: #444;

    @include sp {
      margin-top: 16px;
      font: normal 12px/20px $font-ja;
    }
  }
}
