@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.composition {
  padding-top: 60px;
  margin-top: 60px;
  border-top: 1px solid $border;
  &__title {
    font: bold 14px/1.5 $font-ja;
    padding-left: 20px;
    position: relative;
    margin-top: 24px;
    margin-bottom: 12px;
    @include sp {
      margin-top: 12px;
      font: bold 14px/1.5 $font-ja;
      padding-left: 16px;
    }
    &::before {
      position: absolute;
      content: "";
      width: 16px;
      height: 16px;
      background: $primary;
      top: 50%;
      border-radius: 50%;
      left: 0;
      transform: translateY(-50%);
      @include sp {
        width: 12px;
        height: 12px;
      }
    }
  }
  &__text {
    font: normal 16px/1.5 $font-ja;
    margin-top: 24px;
    margin-bottom: 24px;
    @include sp {
      font: normal 12px/1.5 $font-ja;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
  &__img {
    margin-top: 24px;
    margin-bottom: 24px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    @include sp {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
  &__annotation {
    padding-left: 1em;
    text-indent: -1em;
    font: normal 12px/1.5 $font-ja;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    span {
      display: block;
      padding-left: 1em;
    }
    @include sp {
      font: normal 10px/1.5 $font-ja;
    }
  }
  &__box {
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 20px;
    border: 1px solid $border;
    @include sp {
      margin-top: 12px;
      margin-bottom: 12px;
      padding: 15px;
      font: normal 12px/1.5 $font-ja;
    }
  }
  &__table{
    table , td, th {
      border: 1px solid $border;
      border-collapse: collapse;
      @include sp {
        font: normal 12px/1.5 $font-ja;
      }
    }
    tr{
      background: $bg;
      &._top{
        font-weight: bold;
        background: $primary;
        color: #fff;
        text-align: center;
        td{
          padding: 7px;
        }
      }
    }
    td{
      &._num{
        text-align: right;
      }
      width: 33.333%;
      @include sp {
        width: 18%;
        &:nth-child(2){
          width: 64%;
        }
      }
    }
    td, th {
      padding: 10px 15px;
      @include sp {
        padding: 10px;
      }
    }
  }
  &__modal {

  }
}

