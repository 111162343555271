@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

._link {
  transition: all 0.3s ease 0s;

  &:hover {
    opacity: 0.7;
  }
}
