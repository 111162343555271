@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.officer {
  &Title {
    font: bold 14px/24px $font-ja;
    color: $primary;
    text-align: center;
  }

  &List {
    display: grid;
    gap: 48px;

    @include sp {
      gap: 32px;
      margin-top: 24px;
    }

    &__item {
      &:has(.img) {
        display: grid;
        grid-template-columns: 22.5% auto;
        gap: 24px;

        @include sp {
          grid-template-rows: auto;
          grid-template-columns: 33% 1fr;
          align-items: center;

          .content {
            display: contents;
          }

          .img {
            grid-area: 1 / 1 / 2 / 2;
          }

          .post_and_name {
            grid-area: 1 / 2 / 2 / 3;
          }

          .text {
            grid-area: 2 / 1 / 3 / 3;
            margin-top: 0;
          }
        }
      }

      padding-bottom: 24px;
      border-bottom: 1px solid $border;

      content {
      }

      .post {
        font: bold 16px/32px $font-ja;
        color: #444;

        @include sp {
          font: bold 12px/20px $font-ja;
        }
      }

      .name {
        margin-top: 8px;
        font: bold 24px/40px $font-ja;

        @include sp {
          font: bold 18px/24px $font-ja;
        }
      }

      .text {
        margin-top: 24px;
        font: normal 16px/32px $font-ja;
        color: #666;

        @include sp {
          margin-top: 16px;
          font: normal 12px/20px $font-ja;
        }
      }

      .img {
      }
    }
  }
}
