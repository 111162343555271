@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.card {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 48px 32px 32px 48px;

  &._business,
  &._related,
  &._technology,
  &._contact {
    padding: 32px;

    @include sp {
      padding: 24px;
    }
  }

  &._article {
    gap: 12px;
    padding: 24px;

    @include sp {
      &:not(:first-child) {
        border-top: none;
      }
    }
  }

  &._technology {
    @include pc {
      min-height: 369px;
    }

    @include sp {
      &:not(:first-child) {
        border-top: none;
      }
    }
  }

  @include sp {
    gap: 16px;
    padding: 24px 24px 31px;
  }

  ._top & {
    border-bottom: 1px solid $border;

    &:nth-child(2) {
      border-left: 1px solid $border;
    }
  }

  ._related & {
    @include pc {
      border: 1px solid $border;

      &:nth-child(2n) {
        border-left: none;
      }
    }

    @include sp {
      border: 1px solid $border;
    }
  }

  ._3 & {
    @include pc {
      border: 1px solid $border;

      &:nth-child(3n + 2),
      &:nth-child(3n) {
        border-left: none;
      }

      &:nth-child(n + 4) {
        border-top: none;
      }
    }

    @include sp {
      border: 1px solid $border;
    }
  }

  &__num {
    font: bold 18px/24px $font-ja;
    color: $primary;

    @include sp {
      font: bold 14px/14px $font-ja;
    }
  }

  &__title {
    position: relative;
    padding-bottom: 26px;
    font: bold 28px/32px $font-ja;
    letter-spacing: 2.8px;

    ._business &,
    ._technology & {
      @include pc {
        font: bold 24px/32px $font-ja;
        letter-spacing: 2.4px;
      }
    }

    ._technology & {
      @include pc {
        min-height: 130px;
      }
    }

    ._related & {
      @include pc {
        font: bold 24px/32px $font-ja;
      }
    }

    ._contact & {
      padding-right: 0;
      font: bold 18px/32px $font-ja;
      color: #666;
      letter-spacing: 0.9px;

      @include sp {
        padding-bottom: 0;
        font: bold 14px/24px $font-ja;
        color: $primary;

        &::after {
          display: none;
        }
      }
    }

    span {
      display: block;
      margin-top: 6px;
      font: bold 16px/32px $font-ja;
      letter-spacing: 0.54px;

      @include sp {
        font: normal 14px/24px $font-ja;
      }
    }

    ._business & {
      flex-grow: 1;
    }

    @include pc {
      padding-right: 16px;
    }

    @include sp {
      padding-bottom: 16px;
      font: bold 18px/24px $font-ja;
      letter-spacing: 0.9px;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 64px;
      height: 2px;
      content: "";
      background: $primary;
    }
  }

  &__text {
    flex-grow: 1;
    padding-right: 16px;
    color: #666;

    a {
      color: $primary;
      text-decoration: underline;
    }

    ._business & {
      flex-grow: inherit;
    }

    ._article & {
      padding: 0;
      font: normal 14px/22px $font-ja;

      @include sp {
        font: 12px/20px $font-ja;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    ._technology & {
      padding: 0;
    }

    @include sp {
      font: normal 12px/20px $font-ja;
    }
  }

  &__img {
    position: relative;

    &::before {
      display: block;
      padding-top: 56.25%;
      content: "";
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__btn {
    @include pc {
      text-align: right;
    }

    @include sp {
      margin-top: 8px;
    }

    ._contact & {
      display: grid;
      gap: 24px;
      text-align: left;

      @include sp {
        gap: 8px;
        margin-top: 0;
      }
    }
  }
}
