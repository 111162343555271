@charset "utf-8";

// メディアクエリの基準幅
$bp-sp: 1024px;
$bp-pc: 1025px;

// 色
$white: #fff;
$black: #222;
$primary: #00aec4;
$border: #e3e3e3;
$gray: #888;
$bg: #fbfbfb;
$font-ja: "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
$font-en: "Roboto", sans-serif;

// インナー
$inner: 960px;
