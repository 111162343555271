@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.strong {
  position: relative;
  padding-left: 1em;

  &::before {
    position: absolute;
    top: 11px;
    left: 0;
    width: 9px;
    height: 9px;
    content: "";
    background: #444;

    @include sp {
      top: 5px;
    }
  }
}
