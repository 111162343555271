@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.recruit {
  &__message {
    margin: 120px auto;

    @include pc {
      max-width: 697px;
    }

    @include sp {
      margin: 60px auto;
      text-align: center;
    }
  }

  &__title {
    margin-top: 24px;
    font: bold 24px/40px $font-ja;

    @include sp {
      font: bold 18px/24px $font-ja;
    }

    &._primary {
      margin-top: 32px;
      color: $primary;

      @include pc {
        text-align: center;
      }
    }
  }

  &__titleSub {
    font: bold 18px/32px $font-ja;

    @include pc {
      text-align: center;
    }

    @include sp {
      font: bold 14px/24px $font-ja;
    }

    &._divide {
      padding-top: 60px;
      margin-top: 60px;
      border-top: 1px solid $border;

      @include sp {
        padding-top: 32px;
        margin-top: 32px;
      }
    }
  }

  &__text {
    font: normal 16px/32px $font-ja;

    @include sp {
      font: normal 12px/20px $font-ja;
    }

    &._center {
      margin-top: 24px;

      @include pc {
        text-align: center;
      }

      @include sp {
        margin-top: 16px;
      }
    }
  }

  &List {
    padding-bottom: 60px;
    margin-top: 60px;
    margin-bottom: 60px;
    border-bottom: 1px solid $border;

    @include sp {
      display: grid;
      gap: 32px;
      padding-bottom: 32px;
      margin-top: 32px;
      margin-bottom: 32px;
    }

    &__item {
      display: grid;
      gap: 32px;
      align-items: center;

      @include pc {
        grid-template-columns: 33.3% auto;

        &:not(:first-child) {
          margin-top: -47px;
        }
      }

      @include sp {
        gap: 16px;
      }
    }

    &__img {
      @include sp {
        max-width: 180px;
        margin: 0 auto;
      }
    }
  }

  &__detail {
    margin-top: 48px;
    font: normal 16px/32px $font-ja;

    @include sp {
      font: normal 12px/20px $font-ja;
    }

    .primary {
      display: block;
      font-weight: bold;
      color: $primary;
    }

    strong {
      display: block;
      font-weight: bold;
    }

    a {
      color: $primary;
      text-decoration: underline;
    }
  }
}
