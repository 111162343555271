@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.related {
  padding-top: 60px;
  margin-top: 60px;
  border-top: 1px solid $border;

  @include sp {
    padding-top: 32px;
    margin-top: 32px;
  }

  &__title {
    margin-bottom: 40px;
    font: bold 18px/32px $font-ja;
    text-align: center;

    @include sp {
      margin-bottom: 24px;
      font: bold 14px/24px $font-ja;
    }
  }
}
