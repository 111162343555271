@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.js-tabs {
  margin-right: auto;
  margin-left: auto;

  @include pc {
    margin-top: 50px;
  }

  @include sp {
    display: contents;

    ._about & {
      display: none;
    }

    .news & {
      order: 3;
    }
  }

  &-list {
    display: grid;
    gap: 1px;

    @include pc {
      grid-template-columns: repeat(4, 1fr);

      &._2 {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @include sp {
      position: absolute;
      top: 32px;
      right: 0;
      order: 2;
      width: 200px;
      height: 18px;
    }

    &item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 10px 5px;
        color: #fff;
      }

      @include pc {
        padding: 10px 5px;
        color: #fff;
        background-color: $primary;
        border-top: 1px solid transparent;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent;
        border-radius: 4px 4px 0 0;

        &::after {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 64px;
          height: 2px;
          content: "";
          background: $primary;
          opacity: 0;
          transition: opacity 0.3s;
          transform: translateX(-50%);
        }
      }

      @include sp {
        justify-content: right;
        padding-right: 28px;
        font: normal 12px/1.5 $font-ja;

        &::after {
          position: absolute;
          top: 50%;
          right: 0;
          width: 16px;
          height: 16px;
          content: "";
          background-color: #666;
          mask: url("../img/icon_arrow.svg") no-repeat center center / contain;
          transform: translateY(-50%) rotate(90deg);
        }
      }

      @include pc {
        &:hover {
          color: $primary;
          background: #fff;
          border-color: $primary;

          a {
            color: $primary;
          }
        }

        &._active {
          color: $primary;
          background: #fff;
          border-color: $border;

          a {
            color: $primary;
          }

          &::after {
            opacity: 1;
          }
        }
      }

      &:has(a) {
        padding: 0;
      }
    }
  }

  &-contents {
    width: 100%;

    @include sp {
      order: 3;
    }
  }

  &-content {
    display: none;
    width: 100%;

    &._active {
      display: block;
    }
  }

  .js-tab-select {
    @include sp {
      &-wrap {
        position: absolute;
        top: 35px;
        right: 0;
        order: 2;
        padding-right: 28px;

        ._page-news & {
          top: 144px;
          right: 24px;
        }

        &::after {
          position: absolute;
          top: 3px;
          right: 0;
          width: 16px;
          height: 16px;
          content: "";
          background-color: $black;
          mask: url("../img/icon_arrow.svg") no-repeat center center / contain;
          transform: rotate(90deg);
        }

        &._contact {
          display: none !important;
        }
      }

      direction: rtl;
      font: 12px/1.5 $font-ja !important;
      color: $black;

      option {
        color: $black;
      }
    }
  }
}
