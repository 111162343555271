@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.priceCheck {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $border;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
    width: calc(100% - 8px * 2);
    height: calc(100% - 8px * 2);
    margin-right: auto;
    margin-left: auto;
    background: #fff;
    border-radius: 8px;

    @include sp {
      gap: 16px;
      padding: 24px 16px;
    }
  }

  &__title {
    position: relative;
    padding-bottom: 23px;
    font: bold 24px/1.5 $font-ja;
    text-align: center;

    @include sp {
      padding-bottom: 16px;
      font: bold 18px/24px $font-ja;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 64px;
      height: 2px;
      content: "";
      background: $primary;
      transform: translateX(-50%);
    }
  }

  &__btn {
    display: grid;
    gap: 8px;

    @include sp {
      width: 100%;
    }
  }
}
