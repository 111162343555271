@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.news {
  &__inner {
    position: relative;
    width: min(100% - 32px, 1200px);
    padding-top: 46px;
    padding-bottom: 84px;
    margin-inline: auto;

    @include sp {
      width: min(100% - 24px, 1200px);
      padding-top: 32px;
      padding-bottom: 48px;

      ._top & {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;

    @include sp {
      display: contents;
    }
  }

  &__content {
    &Wrap {
      h2 {
        padding-bottom: 32px;
        margin-top: 16px;
        margin-bottom: 32px;
        font: bold 24px/40px $font-ja;
        border-bottom: 1px solid $border;

        @include sp {
          padding-bottom: 0;
          margin-bottom: 24px;
          font: bold 14px/24px $font-ja;
          border: none;

          ._newsDetail & {
            order: 2;
          }
        }
      }

      @include sp {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
      }
    }

    @include sp {
      order: 3;
    }

    .annotation {
      margin-top: 12px;
      font: normal 14px/24px $font-ja;
      color: #888;

      @include sp {
        font: normal 10px/16px $font-ja;
      }
    }

    h3 {
      margin-top: 32px;
      font: bold 16px/32px $font-ja;

      @include sp {
        margin-top: 24px;
        font: bold 12px/20px $font-ja;
      }
    }

    p {
      font: normal 16px/32px $font-ja;

      @include sp {
        font: normal 12px/20px $font-ja;
      }
    }

    img {
      display: block;
      width: 100%;
      max-width: 960px;
      height: auto;
      margin-top: 48px;
      margin-bottom: 48px;

      @include sp {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
    .aligncenter {
      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .wp-block-image {
      &:has( > .alignright),
      &:has( > .alignleft) {
        &::after {
          content: '';
          display: block;
          clear: both;
        }
      }
    }
  }

  &Box {
    display: grid;
    grid-template-columns: 200px auto;

    @include sp {
      display: contents;
    }
  }

  &__search {
    width: 100%;
    max-height: 40px;
    margin-left: auto;

    @include pc {
      max-width: 444px;
    }

    @include sp {
      width: 52%;
    }

    &Box {
      display: flex;
      gap: 24px;
      width: 100%;
      margin-left: auto;

      @include sp {
        gap: 12px;
        order: 1;
        padding: 12px 0;
        clip-path: inset(0 -100vmax);
        background: #fbfbfb;
        box-shadow: 0 0 0 100vmax #fbfbfb;
      }
    }

    form {
      position: relative;
      width: 100%;

      .box {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 10px 74px 10px 10px;
        background-image: linear-gradient(90deg, rgba(227, 227, 227, 1) 86%, rgba(0, 174, 196, 1) 64px);
        border-radius: 20px;

        @include sp {
          padding: 10px 48px 10px 10px;
          background: #fff;
        }
      }

      .submit {
        position: absolute;
        top: 50%;
        right: 24px;
        width: 12px;
        height: 12px;
        font-size: 0 !important;
        cursor: pointer;
        background: $primary;
        background-color: #fff;
        border: none;
        mask: url("../img/icon_search.svg") no-repeat center center / contain;
        transform: translateY(-50%);

        @include sp {
          width: 12px;
          height: 12px;
          background-color: $primary;
        }
      }
    }
  }

  &__select {
    position: relative;
    max-height: 40px;
    background: $primary;
    border-radius: 20px;

    @include sp {
      width: 48%;
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 12px;
      width: 16px;
      height: 16px;
      content: "";
      background-color: #fff;
      mask: url("../img/icon_arrow.svg") no-repeat center center / contain;
      transform: translateY(-50%) rotate(90deg);
    }

    select {
      width: 100%;
      height: 100%;
      padding: 10px 40px 10px 30px;
      font: normal 14px/1.5 $font-ja !important;
      color: #fff;
      text-align: center;
      option{
        color: $black;
      }

      @include sp {
        padding: 5px 40px 5px 20px;
        font: normal 12px/1.5 $font-ja !important;
      }
    }
  }

  &__sectionTitle {
    font: bold 24px/1.5 $font-ja;
    letter-spacing: 2.4px;

    @include pc {
      padding-left: 16px;
    }

    @include sp {
      position: relative;
      order: 1;
      padding-bottom: 16px;
      font: bold 18px/24px $font-ja;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 64px;
        height: 2px;
        content: "";
        background: $primary;
      }
    }
  }

  &__btn {
    @include pc {
      margin-left: auto;
    }

    @include sp {
      order: 4;
      margin-top: 24px;
    }
  }

  &List {
    display: grid;
    gap: 32px;
    margin-top: 48px;

    @include sp {
      gap: 24px;
      margin-top: 32px;

      ._searchResult & {
        order: 3;
      }
    }

    &__item {
      @include pc {
        padding-right: 8px;
        padding-left: 8px;
      }
    }
  }

  &__label {
    display: flex;
    gap: 24px;
    align-items: center;
    color: $gray;

    ._article & {
      gap: 12px;
      font: normal 14px/1.5 $font-ja;

      @include sp {
        font: normal 10px/1.5 $font-ja;
      }
    }

    @include sp {
      gap: 12px;

      ._newsDetail & {
        order: 1;
      }
    }
  }

  &__date {
    color: $gray;

    @include sp {
      font: normal 10px/1.5 $font-ja;
    }
  }

  &__cat {
    padding-left: 24px;
    color: $gray;
    border-left: 1px solid $border;

    ._article & {
      padding-left: 12px;
    }

    @include sp {
      padding-left: 12px;
      font: normal 10px/1.5 $font-ja;
    }
  }

  &__title {
    margin-top: 16px;

    @include sp {
      margin-top: 8px;
      font: normal 12px/20px $font-ja;
    }

    a {
      color: #666;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.snsList {
  display: flex;
  gap: 24px;
  align-items: center;

  @include pc {
    justify-content: right;
    margin-right: 5px;
  }

  @include sp {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $border;

    ._newsDetail & {
      order: 3;
    }
  }
}

.searchResult {
  margin-top: 60px;
  font: bold 24px/40px $font-ja;

  &._noHit {
    padding-bottom: 300px;
    font-weight: normal;
    color: #666;

    @include sp {
      padding-bottom: 120px;
    }
  }

  @include sp {
    order: 3;
    margin-top: 32px;
    font: bold 14px/24px $font-ja;
  }

  .keyword {
    color: $primary;
  }

  .num {
    color: $primary;
  }
}
