@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.pagetop {
  position: sticky;
  bottom: 56px;
  z-index: 3;
  text-align: right;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease, opacity 0.3s ease;

  &._show {
    visibility: visible;
    opacity: 1;
  }

  @include sp {
    bottom: 32px;
  }

  a {
    position: absolute;
    top: -24px;
    right: 32px;
    display: inline-block;
    width: 48px;
    height: 48px;
    background: $primary;
    border: 1px solid $primary;
    border-radius: 8px;
    transition: all 0.3s;

    span {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        content: "";
        background-color: #fff;
        mask: url("../img/icon_arrow.svg") no-repeat center center / contain;
        transition: all 0.3s;
        transform: translate(-50%, -50%) rotate(-90deg);
      }
    }

    @include sp {
      top: -20px;
      right: 12px;
      width: 40px;
      height: 40px;
    }

    &:hover {
      @include pc {
        background: #fff;

        span {
          &::before {
            background-color: $primary;
          }
        }
      }
    }
  }
}

.footer {
  border-top: 1px solid $border;

  @include pc {
    height: 128px;
  }

  &__inner {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 100%;
    padding-right: 32px;
    padding-left: 32px;

    @include sp {
      display: block;
      padding: 24px 24px 45px;
    }
  }

  &__copy {
    font: bold 14px/12px $font-en;
    white-space: nowrap;

    @include sp {
      margin-top: 32px;
      font: bold 10px/12px $font-en;
    }
  }

  &Link {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-left: auto;

    @include pc {
      justify-content: right;
    }

    @include sp {
      gap: 12px;
    }

    &__item {
      font: normal 14px/1.5 $font-ja;
      letter-spacing: 1.4px;

      @include sp {
        font: normal 10px/1.5 $font-ja;
      }

      &:not(:last-child) {
        padding-right: 24px;
        border-right: 1px solid $border;

        @include sp {
          padding-right: 12px;
        }
      }

      a {
        color: $gray;

        @include sp {
          font: normal 10px/1.5 $font-ja;
        }

        &:hover {
          color: $primary;
        }
      }
    }
  }
}
