@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.section {
  &__inner {
    width: min(100% - 32px, 960px);
    padding-top: 60px;
    padding-bottom: 120px;
    margin-inline: auto;

    &._narrow {
      padding-top: 32px;
    }

    &._wide {
      width: min(100% - 32px, 1200px);

      @include sp {
        width: min(100% - 48px, 1200px);
      }
    }

    @include sp {
      width: min(100% - 48px, 960px);
      padding-top: 32px;
      padding-bottom: 48px;

      .news & {
        display: flex;
        flex-direction: column;
        padding-top: 0;
      }
    }
  }

  &__title {
    position: relative;
    padding-bottom: 24px;
    font: bold 28px/32px $font-ja;
    color: #222;
    letter-spacing: 2.8px;
    &._gpm{
      @include sp {
        font-size: 16px;
        padding-right: 20px;
      }
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 64px;
      height: 2px;
      content: "";
      background: $primary;
    }

    @include sp {
      padding-bottom: 16px;
      font: bold 18px/24px $font-ja;

      .news & {
        order: 2;
        margin-top: 32px;
      }
    }
  }
}
