@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.modal{
  &Button{
    text-decoration: underline;
    display: flex;
    margin-left: auto;
    text-decoration-color: $black;
    span {
      display: inline-block;
      padding-left: 20px;
      position: relative;
      color: $black;
      @include sp {
        font: normal 12px/1.5 $font-ja;
        padding-left: 16px;
      }
      &::before {
        position: absolute;
        content: "";
        background-color: $primary;
        width: 14px;
        height: 18px;
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
        left: 0;
        top: 3px;
        @include sp {
          top: 3px;
          width: 10px;
          height: 12px;
        }
      }
    }
  }
  &Content{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    margin: auto;
    width: 100%;
    height: 80%;
    max-width: 900px;
    padding: 60px 25px;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    overflow-y: scroll;
    border-radius: 70px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
      display: none;
    }
    &Open{
      visibility: visible;
      opacity: 1;
    }
    @include sp {
      border-radius: 50px;
      width: 90%;
      padding: 40px 10px;
    }
  }
  &Overflow{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 25px;
    @include sp {
      padding: 0 10px;
    }
  }
  &Close{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
    &:hover{
      cursor: pointer;
    }
    @include sp {
      top: 3px;
      width: 18px;
      height: 18px;
    }
  }
  &Overlay{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 200vw;
    height: 200vh;
    background-color: rgba(0, 0, 0, 0.6);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    &Open{
      visibility: visible;
      opacity: 1;
    }
  }
}
