@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.column {
  display: grid;

  &._2 {
    @include pc {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &._3 {
    @include pc {
      grid-template-columns: repeat(3, 1fr);
    }

    @include sp {
      gap: 8px;
    }
  }

  &._noGap {
    @include sp {
      gap: 0;
    }
  }

  &._related {
    @include sp {
      gap: 8px;
    }
  }

  &._contact {
    margin-top: 60px;

    @include sp {
      margin-top: 32px;
    }
  }

  .business__title + & {
    margin-top: 40px;
    @include sp {
      margin-top: 24px;
    }
  }
}
