@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.technology {
  &__title {
    margin-top: 24px;
    font: bold 24px/40px $font-ja;
    color: $primary;
    letter-spacing: 1.2px;

    @include sp {
      margin-top: 16px;
      font: bold 18px/24px $font-ja;
    }
  }

  &__text {
    margin-top: 48px;
    font: bold 18px/32px $font-ja;

    @include sp {
      margin-top: 16px;
      font: bold 14px/24px $font-ja;
    }
  }

  &__img {
    margin-top: 12px;
    margin-bottom: 48px;
    text-align: center;

    @include sp {
      margin-bottom: 16px;
    }
  }

  &__example {
    max-width: 630px;
    margin: 48px auto;

    @include sp {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }

  &List {
    &__item {
      padding-top: 60px;
      padding-bottom: 24px;
      border-top: 1px solid $border;

      @include sp {
        padding-top: 32px;
      }
    }

    &__title {
      font: bold 24px/40px $font-ja;

      @include sp {
        font: bold 18px/24px $font-ja;
      }

      &._donation {
        font: bold 18px/32px $font-ja;
        text-align: center;

        @include sp {
          font: bold 14px/24px $font-ja;
        }
      }
    }

    &__content {
      margin-top: 24px;

      @include sp {
        margin-top: 16px;
      }

      &._01 {
        display: grid;
        gap: 24px;

        @include pc {
          grid-template-columns: 22.5% auto;
        }

        @include sp {
          gap: 16px;
        }
      }

      &._02 {
        display: grid;
        gap: 24px;

        @include pc {
          grid-template-columns: 50% auto;
        }

        @include sp {
          gap: 16px;
        }
      }

      &:not(:has(.technologyList__img)) {
        margin-top: 48px;

        @include sp {
          margin-top: 16px;
        }
      }

      &:not(:has(.technologyList__img)) {
        .technologyList__text {
          color: #444;
        }
      }

      a {
        display: block;
        font-variant: normal 14px/24px $font-ja;
        color: #888;
        text-decoration: underline;

        &:first-child {
          margin-top: 12px;
        }
      }

      strong {
        display: block;
        font-weight: bold;
      }
    }

    &__img {
      @include sp {
        margin-right: auto;
        margin-left: auto;

        ._01 & {
          max-width: 120px;
        }

        ._02 & {
          max-width: 212px;
        }
      }
    }

    &__text {
      font: normal 16px/32px $font-ja;
      color: #666;

      @include sp {
        font: normal 12px/20px $font-ja;
      }
    }
  }
}
