@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

._rule {
  font: normal 16px/32px $font-ja;
  color: #444;

  @include sp {
    font: normal 12px/20px $font-ja;
  }

  p {
    &._right {
      text-align: right;
    }

    a {
      &._link {
        color: $primary;
        text-decoration: underline;
      }
    }
  }

  .rule {
    &__text {
      margin-top: 48px;
      color: #444;

      @include sp {
        margin-top: 32px;
      }
    }

    &__detail {
      margin-top: 48px;
      margin-bottom: 60px;
      text-align: right;

      @include sp {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }

    &__contact {
      margin-top: 60px;
      margin-bottom: 24px;

      @include sp {
        margin-top: 32px;
      }

      span {
        display: block;
        margin-top: 12px;
        font: normal 14px/24px $font-ja;
        color: #888;

        @include sp {
          font: normal 10px/16px $font-ja;
        }
      }
    }

    &Box {
      padding: 24px;
      margin: 24px 0;
      background: #fbfbfb;
      border: 1px solid #e3e3e3;
      border-radius: 16px;

      @include sp {
        padding: 16px;
        margin: 12px 0;
        border-radius: 8px;
      }

      p {
        margin-bottom: 12px;
        font: bold 16px/32px $font-ja;
        color: #222;
        letter-spacing: 1.6px;

        @include sp {
          margin-bottom: 8px;
        }

        &:not(:first-child) {
          margin-top: 24px;

          @include sp {
            margin-top: 8px;
          }
        }

        @include sp {
          font: bold 12px/20px $font-ja;
        }
      }

      ol,
      ul {
        color: #222;

        li {
          position: relative;
          padding-left: 2em;
          letter-spacing: 1.6px;

          &:not(:first-child) {
            margin-top: 12px;

            @include sp {
              margin-top: 8px;
            }
          }
        }
      }

      ul {
        li {
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            font: normal 16px/32px $font-ja;
            content: "・";

            @include sp {
              font: normal 12px/20px $font-ja;
            }
          }
        }
      }

      ol {
        counter-reset: count 0;

        li {
          text-indent: -1.5em;

          &::before {
            content: counter(count) ". ";
            counter-increment: count 1;
          }
        }
      }
    }

    &__dl {
      margin-top: 48px;
      margin-bottom: 32px;
      border: 1px solid $border;

      @include sp {
        margin-top: 32px;
        margin-bottom: 24px;
      }

      dl {
        display: flex;

        &:not(:first-child) {
          border-top: 1px solid $border;
        }

        @include sp {
          font: normal 10px/16px $font-ja;
        }

        dt,
        dd {
          padding: 24px;

          @include sp {
            padding: 12px;
          }
        }

        dt {
          width: 45%;
          font-weight: bold;
          color: $primary;

          @include sp {
            width: 37%;
          }
        }

        dd {
          width: 55%;
          border-left: 1px solid $border;

          @include sp {
            width: 63%;
          }
        }
      }
    }

    &List {
      color: #222;

      ._pdf {
        display: block;
        margin-top: 12px;

        @include sp {
          margin-top: 8px;
        }
      }

      &__item {
        padding-top: 48px;
        padding-bottom: 60px;
        border-bottom: 1px solid $border;

        &:not(:first-child) {
          padding-top: 60px;
          border-top: 1px solid $font-ja;

          @include sp {
            padding-top: 32px;
          }
        }

        @include sp {
          padding-top: 32px;
          padding-bottom: 24px;
        }
      }

      &__title {
        margin-bottom: 24px;
        font: bold 24px/40px $font-ja;
        color: $primary;

        @include sp {
          font: bold 18px/24px $font-ja;
        }
      }

      &__subTitle {
        margin-bottom: 24px;
        font: bold 18px/32px $font-ja;

        &:not(:first-child) {
          margin-top: 24px;
        }

        @include sp {
          font: bold 14px/24px $font-ja;
        }
      }

      &__subText {
        margin-bottom: 6px;
        font: bold 16px/32px $font-ja;

        @include sp {
          font: bold 12px/20px $font-ja;
        }
      }

      &__text {
        position: relative;
        padding-left: 16px;
        margin-top: 24px;
        margin-bottom: 12px;
        font: bold 16px/32px $font-ja;

        @include sp {
          padding-left: 12px;
          font: bold 12px/20px $font-ja;
        }

        a {
          text-decoration: underline;
        }

        &::before {
          position: absolute;
          top: 10px;
          left: 0;
          width: 12px;
          height: 12px;
          content: "";
          background: $primary;

          @include sp {
            top: 6px;
            width: 8px;
            height: 8px;
          }
        }
      }

      &__column {
        &:not(:first-child) {
          @include sp {
            padding-top: 24px;
            margin-top: 32px;
            border-top: 1px solid $border;
          }
        }

        &Wrap {
          display: grid;

          @include pc {
            grid-template-columns: repeat(2, 1fr);
            gap: 32px;
          }
        }
      }
    }
  }
}

.privacy {
  &List {
    display: grid;
    gap: 60px 32px;
    padding-top: 60px;
    margin-top: 60px;
    border-top: 1px solid $border;

    @include pc {
      grid-template-columns: repeat(2, 1fr);
    }

    @include sp {
      gap: 32px;
      padding-top: 32px;
      margin-top: 32px;
    }

    &__item {
      padding-bottom: 60px;
      border-bottom: 1px solid $border;

      @include sp {
        padding-bottom: 32px;
      }

      &__title {
        margin-bottom: 24px;
        font: bold 24px/40px $font-ja;
        color: $primary;
        letter-spacing: 1.2px;

        @include sp {
          font: bold 18px/24px $font-ja;
        }
      }

      &__link {
      }
    }
  }
}
