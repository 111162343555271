@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

html,
input,
textarea,
select,
button {
  font-family: $font-ja;
  font-size: 16px;
  font-weight: 500;
}

html {
  font-size: 16px;
  line-height: 1.5;
  color: $black;
  word-break: break-all;
  background: #fff;
  scroll-behavior: smooth;

  @include pc {
    scroll-padding-top: 88px;
  }

  @include sp {
    scroll-padding-top: 48px;
  }
}

body {
  margin: 0;
  font-family: $font-ja;
  color: $black;
  letter-spacing: 0.8px;
  background: #fff;
  &.IsScrollAllowed{
    overflow: hidden;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  margin: 0;
  vertical-align: top;
  border: 0;
}

p {
  padding: 0;
  margin: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

a {
  color: #000;
  text-decoration: none;
}

input,
select,
textarea,
button {
  padding: 0;
  margin: 0;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
}

select::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
}

button {
  cursor: pointer;
  border: none;
}

label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

main {
  display: block;
}

dl,
dt,
dd {
  padding: 0;
  margin: 0;
}

@include pc {
  .sp {
    display: none !important;
  }
}

@include sp {
  body {
    position: relative;
    font-size: 14px;
    appearance: none;
    text-size-adjust: 100%;
  }

  input[type="submit"] {
    text-size-adjust: 100%;
  }

  input,
  select,
  textarea {
    font-size: 16px !important;
  }

  .pc {
    display: none !important;
  }
}
