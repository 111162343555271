@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.access {
  &Title {
    font: bold 14px/24px $font-ja;
    color: $primary;
    text-align: center;
  }

  &List {
    display: grid;
    gap: 60px;
    margin-top: 24px;

    @include sp {
      gap: 32px;
    }

    &__item {
      display: grid;
      gap: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid $border;

      @include sp {
        gap: 16px;
        padding-bottom: 16px;
      }

      .name {
        font: bold 24px/40px $font-ja;
        color: $primary;

        @include sp {
          font: bold 18px/24px $font-ja;
        }
      }

      .map {
        position: relative;
        width: 100%;

        &::before {
          display: block;
          padding-top: 33.3%;
          content: "";

          @include sp {
            padding-top: 58%;
          }
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .address {
        font: normal 14px/24px $font-ja;

        @include sp {
          font: normal 12px/20px $font-ja;
        }

        span {
          font: normal 12px/1.5 $font-ja;
          color: #888;

          @include sp {
            font: normal 10px/1.5 $font-ja;
          }
        }
      }
    }
  }
}
