@use "../setting/setting" as s;
@use "sass:math";

// メディアクエリ
@mixin pc {
  @media only screen and (min-width: #{s.$bp-pc}) {
    @content;
  }
}

@mixin sp {
  @media only screen and (max-width: #{s.$bp-sp}) {
    @content;
  }
}

@mixin table {
  @media only screen and (max-width: 600px) {
    @content;
  }
}

@mixin pc- {
  @media only screen and (min-width: 1025px) and (max-width: 1235px) {
    @content;
  }
}
