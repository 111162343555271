@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.about {
  &__inner {
    padding-top: 60px;

    @include sp {
      padding-top: 32px;
    }
  }

  &__img {
    max-width: 420px;
    margin-top: 60px;
    margin-right: auto;
    margin-left: auto;

    @include sp {
      margin-top: 28px;
    }
  }
}
