@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.corporate {
  &Title {
    font: bold 14px/24px $font-ja;
    color: $primary;
    text-align: center;
  }

  &Info {
    dl {
      display: flex;
      padding-top: 24px;
      padding-bottom: 24px;
      font: normal 16px/32px $font-ja;
      color: #666;
      border-bottom: 1px solid $border;

      @include sp {
        display: block;
        font: normal 12px/28px $font-ja;
      }

      dt {
        font-weight: bold;

        @include pc {
          width: 23%;
        }
      }

      dd {
        @include pc {
          width: 77%;
        }

        @include sp {
          margin-top: 12px;
        }
      }
    }
  }
}
