@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.js-accordion {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  &List {
    width: 100%;
    background-color: #fff;

    &__item {
      width: 100%;

      &:not(:first-child) {
        margin-top: 10px;
      }

      &__switch {
        display: flex;
        align-items: center;
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 24px;
        cursor: pointer;
        border-bottom: 1px solid $border;
      }

      &__inner {
        width: 100%;
        overflow: hidden;
        transition: all 0.5s;
      }

      &__content {
        width: 100%;
        padding-bottom: 16px;
        background-color: #fbfbfb;

        ul {
          li {
            &:not(:first-child) {
              border-top: 1px solid #e3e3e3;
            }

            a {
              display: block;
              padding: 16px 0 16px 48px;
              font: normal 12px/1.5 $font-ja;
              color: #666;
            }
          }
        }
      }
    }
  }
}

.js-accordionList__item__switch:not([data-is-open="false"]) {
  color: $primary;
}
