@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.btn {
  display: inline-block;
  padding: 8.5px 17px;
  font: normal 14px/1.5 $font-ja;
  color: #fff;
  text-align: center;
  letter-spacing: 0.7px;
  background: $primary;
  border: 1px solid $primary;
  border-radius: 20px;
  transition: all 0.3s;

  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  @include sp {
    padding: 10px 18px;
    font: normal 12px/1.5 $font-ja;
  }

  ._normal {
    display: block;
    padding: 0 !important;
    font-weight: normal;
    letter-spacing: 0;
  }

  &:hover {
    color: $primary;
    background: #fff;
  }

  &._bold {
    font-weight: bold;
  }

  &._gray {
    padding: 13.5px 24px;
    color: $black;
    text-align: left;
    background: #f7f7f7;
    border: 1px solid $gray;
    border-radius: 8px;

    &._arrow {
      position: relative;
      padding-right: 28px;

      &::after {
        position: absolute;
        top: 50%;
        right: 24px;
        width: 16px;
        height: 16px;
        content: "";
        background-color: #222;
        mask: url("../img/icon_arrow.svg") no-repeat center center / contain;
        transition: all 0.3s;
        transform: translateY(-50%);

        @include sp {
          right: 12px;
        }
      }
    }

    &:hover {
      color: #fff;
      background: $primary;
      border-color: $primary;

      &::after {
        background-color: #fff;
      }
    }

    ._contact & {
      @include pc {
        min-height: 80px;
      }

      @include sp {
        padding-left: 12px;
      }
    }
  }

  &._arrow {
    span {
      position: relative;
      padding-right: 28px;

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 16px;
        height: 16px;
        content: "";
        background-color: #fff;
        mask: url("../img/icon_arrow.svg") no-repeat center center / contain;
        transition: all 0.3s;
        transform: translateY(-50%);
      }
    }

    &:hover {
      span {
        &::after {
          background-color: $primary;
        }
      }
    }

    ._contact & {
      span {
        @include sp {
          padding-right: 12px;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  &._ex {
    position: relative;
    padding-right: 64px;

    @include sp {
      padding-right: 46px;
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 24px;
      width: 16px;
      height: 16px;
      content: "";
      background-color: $black;
      mask: url("../img/icon_ex.svg") no-repeat center center / contain;
      transition: all 0.3s;
      transform: translateY(-50%);

      @include sp {
        right: 12px;
        width: 12px;
        height: 12px;
      }
    }

    &:hover {
      &::after {
        background-color: #fff;
      }
    }
  }
}

._pdf {
  position: relative;
  padding-left: 22px;
  font: normal 14px/1.5 $font-ja;
  text-decoration: underline;

  @include sp {
    font-size: 10px;
  }

  &::before {
    position: absolute;
    top: 1px;
    left: 0;
    width: 16px;
    height: 16px;
    content: "";
    background-color: #222;
    mask: url("../img/icon_pdf.svg") no-repeat center center / contain;

    @include sp {
      top: 0;
    }
  }
}
