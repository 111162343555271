@charset "UTF-8";
html,
input,
textarea,
select,
button {
  font-family: "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

html {
  font-size: 16px;
  line-height: 1.5;
  color: #222;
  word-break: break-all;
  background: #fff;
  scroll-behavior: smooth;
}
@media only screen and (min-width: 1025px) {
  html {
    scroll-padding-top: 88px;
  }
}
@media only screen and (max-width: 1024px) {
  html {
    scroll-padding-top: 48px;
  }
}

body {
  margin: 0;
  font-family: "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #222;
  letter-spacing: 0.8px;
  background: #fff;
}
body.IsScrollAllowed {
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  margin: 0;
  vertical-align: top;
  border: 0;
}

p {
  padding: 0;
  margin: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

a {
  color: #000;
  text-decoration: none;
}

input,
select,
textarea,
button {
  padding: 0;
  margin: 0;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
}

select::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
}

button {
  cursor: pointer;
  border: none;
}

label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

main {
  display: block;
}

dl,
dt,
dd {
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 1025px) {
  .sp {
    display: none !important;
  }
}
@media only screen and (max-width: 1024px) {
  body {
    position: relative;
    font-size: 14px;
    appearance: none;
    text-size-adjust: 100%;
  }
  input[type=submit] {
    text-size-adjust: 100%;
  }
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
  .pc {
    display: none !important;
  }
}
.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 88px;
  background: #fff;
  transition: all 0.3s;
}
.header._topPage {
  height: 128px;
}
@media only screen and (max-width: 1024px) {
  .header._topPage {
    height: 66px;
  }
  .header._topPage .drawer {
    top: 66px;
  }
  .header._topPage .header__logo {
    width: 144px;
  }
}
.header._topPage .drawerButton {
  height: 66px;
}
.header._topPage .drawerWrap._active .drawerButton__bottom {
  top: 37%;
}
.header._scrolled {
  height: 88px;
}
@media only screen and (max-width: 1024px) {
  .header._scrolled {
    height: 48px;
  }
  .header._scrolled .header__logo {
    width: 108px;
  }
  .header._scrolled .drawerButton {
    height: 48px;
  }
  .header._scrolled .drawer {
    top: 48px;
  }
  .header._scrolled .drawerWrap._active .drawerButton__bottom {
    top: 28%;
  }
}
@media only screen and (max-width: 1024px) {
  .header {
    height: 48px;
    border-bottom: 1px solid #e3e3e3;
  }
}
.header__inner {
  align-items: center;
  height: 100%;
  padding-right: 32px;
  padding-left: 32px;
}
@media only screen and (min-width: 1025px) {
  .header__inner {
    display: grid;
    grid-template-columns: 172px auto;
  }
}
@media only screen and (max-width: 1024px) {
  .header__inner {
    padding-right: 24px;
    padding-left: 24px;
  }
}
.header__logo {
  position: relative;
  z-index: 100;
}
@media only screen and (max-width: 1024px) {
  .header__logo {
    display: flex;
    align-items: center;
    width: 108px;
    height: 100%;
    padding-top: 5px;
    transition: all 0.3s;
  }
}
.headerLink {
  position: relative;
  display: flex;
  gap: 26px;
  align-items: center;
  height: 100%;
  margin-left: auto;
}
@media only screen and (max-width: 1024px) {
  .headerLink {
    display: none;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1235px) {
  .headerLink {
    gap: 10px;
  }
}
.headerLink__item {
  position: static;
  display: flex;
  align-items: center;
  height: 100%;
  transition: all 0.3s;
}
@media only screen and (min-width: 1025px) and (max-width: 1235px) {
  .headerLink__item {
    font: bold 14px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.headerLink__item:hover .subMenu {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}
.headerLink__itemLink {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: bold;
}
.headerLink__itemLink::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  content: "";
  background: #00aec4;
  opacity: 0;
  transition: opacity 0.3s;
}
.headerLink__itemLink._current::after {
  opacity: 1;
}
.headerLink__item:hover .headerLink__itemLink {
  color: #00aec4;
}
.headerLink__item:hover .headerLink__itemLink::after {
  opacity: 1;
}
.header .subMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 32px 32px 48px 0;
  clip-path: inset(0 -100vmax);
  pointer-events: none;
  visibility: hidden;
  background: rgba(102, 102, 102, 0.99);
  box-shadow: 0 0 0 100vmax rgba(102, 102, 102, 0.99);
  opacity: 0;
  transition: all 0.3s;
}
.header .subMenu__inner {
  display: grid;
  gap: 16px;
  max-width: 288px;
}
.header .subMenu__itemTitle {
  margin-bottom: 32px;
  font: bold 16px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #fff;
}
.header .subMenu__itemLink {
  position: relative;
  display: block;
  font: normal 16px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #e3e3e3;
  transition: all 0.3s;
}
.header .subMenu__itemLink:not(:first-child) {
  margin-top: 16px;
}
.header .subMenu__itemLink::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  height: 16px;
  content: "";
  background-color: #e3e3e3;
  mask: url("../img/icon_arrow.svg") no-repeat center center/contain;
  transition: all 0.3s;
  transform: translateY(-50%);
}
.header .subMenu__itemLink:hover {
  opacity: 0.5;
}
.header .subMenu__itemLink:hover::after {
  opacity: 0.5;
}

.header._about .headerLink__itemLink._about::after {
  opacity: 1;
}
.header._business .headerLink__itemLink._business::after {
  opacity: 1;
}
.header._technology .headerLink__itemLink._technology::after {
  opacity: 1;
}
.header._news .headerLink__itemLink._news::after {
  opacity: 1;
}
.header._sustainability .headerLink__itemLink._sustainability::after {
  opacity: 1;
}
.header._ir .headerLink__itemLink._ir::after {
  opacity: 1;
}
.header._recruit .headerLink__itemLink._recruit::after {
  opacity: 1;
}
.header._contact .headerLink__itemLink._contact::after {
  opacity: 1;
}

.pagetop {
  position: sticky;
  bottom: 56px;
  z-index: 3;
  text-align: right;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease, opacity 0.3s ease;
}
.pagetop._show {
  visibility: visible;
  opacity: 1;
}
@media only screen and (max-width: 1024px) {
  .pagetop {
    bottom: 32px;
  }
}
.pagetop a {
  position: absolute;
  top: -24px;
  right: 32px;
  display: inline-block;
  width: 48px;
  height: 48px;
  background: #00aec4;
  border: 1px solid #00aec4;
  border-radius: 8px;
  transition: all 0.3s;
}
.pagetop a span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.pagetop a span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  content: "";
  background-color: #fff;
  mask: url("../img/icon_arrow.svg") no-repeat center center/contain;
  transition: all 0.3s;
  transform: translate(-50%, -50%) rotate(-90deg);
}
@media only screen and (max-width: 1024px) {
  .pagetop a {
    top: -20px;
    right: 12px;
    width: 40px;
    height: 40px;
  }
}
@media only screen and (min-width: 1025px) {
  .pagetop a:hover {
    background: #fff;
  }
  .pagetop a:hover span::before {
    background-color: #00aec4;
  }
}

.footer {
  border-top: 1px solid #e3e3e3;
}
@media only screen and (min-width: 1025px) {
  .footer {
    height: 128px;
  }
}
.footer__inner {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 100%;
  padding-right: 32px;
  padding-left: 32px;
}
@media only screen and (max-width: 1024px) {
  .footer__inner {
    display: block;
    padding: 24px 24px 45px;
  }
}
.footer__copy {
  font: bold 14px/12px "Roboto", sans-serif;
  white-space: nowrap;
}
@media only screen and (max-width: 1024px) {
  .footer__copy {
    margin-top: 32px;
    font: bold 10px/12px "Roboto", sans-serif;
  }
}
.footerLink {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-left: auto;
}
@media only screen and (min-width: 1025px) {
  .footerLink {
    justify-content: right;
  }
}
@media only screen and (max-width: 1024px) {
  .footerLink {
    gap: 12px;
  }
}
.footerLink__item {
  font: normal 14px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  letter-spacing: 1.4px;
}
@media only screen and (max-width: 1024px) {
  .footerLink__item {
    font: normal 10px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.footerLink__item:not(:last-child) {
  padding-right: 24px;
  border-right: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .footerLink__item:not(:last-child) {
    padding-right: 12px;
  }
}
.footerLink__item a {
  color: #888;
}
@media only screen and (max-width: 1024px) {
  .footerLink__item a {
    font: normal 10px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.footerLink__item a:hover {
  color: #00aec4;
}

.mv {
  display: grid;
}
@media only screen and (min-width: 1025px) {
  .mv {
    grid-template-columns: 70% auto;
  }
}
.mv__inner {
  padding: 73px 32px 32px 48px;
  background: #fbfbfb;
}
@media only screen and (max-width: 1024px) {
  .mv__inner {
    padding: 72px 30px 36px 24px;
  }
}
.mv__title._en {
  font: 500 64px/64px "Roboto", sans-serif;
  letter-spacing: 2.8px;
}
@media only screen and (max-width: 1024px) {
  .mv__title._en {
    font: bold 30px/1 "Roboto", sans-serif;
    letter-spacing: 1.2px;
  }
}
.mv__title._ja {
  margin-top: 12px;
  font: bold 28px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  letter-spacing: 2.8px;
}
@media only screen and (max-width: 1024px) {
  .mv__title._ja {
    margin-top: 6px;
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    letter-spacing: 0.7px;
  }
}
.mv__img {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.mv__text {
  margin-top: 32px;
  color: #666;
}
@media only screen and (max-width: 1024px) {
  .mv__text {
    margin-top: 12px;
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
@media only screen and (min-width: 1025px) {
  .mv__btn {
    text-align: right;
  }
}
@media only screen and (max-width: 1024px) {
  .mv__btn {
    margin-top: 24px;
    text-align: center;
  }
}

.btn {
  display: inline-block;
  padding: 8.5px 17px;
  font: normal 14px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #fff;
  text-align: center;
  letter-spacing: 0.7px;
  background: #00aec4;
  border: 1px solid #00aec4;
  border-radius: 20px;
  transition: all 0.3s;
}
.btn span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  .btn {
    padding: 10px 18px;
    font: normal 12px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.btn ._normal {
  display: block;
  padding: 0 !important;
  font-weight: normal;
  letter-spacing: 0;
}
.btn:hover {
  color: #00aec4;
  background: #fff;
}
.btn._bold {
  font-weight: bold;
}
.btn._gray {
  padding: 13.5px 24px;
  color: #222;
  text-align: left;
  background: #f7f7f7;
  border: 1px solid #888;
  border-radius: 8px;
}
.btn._gray._arrow {
  position: relative;
  padding-right: 28px;
}
.btn._gray._arrow::after {
  position: absolute;
  top: 50%;
  right: 24px;
  width: 16px;
  height: 16px;
  content: "";
  background-color: #222;
  mask: url("../img/icon_arrow.svg") no-repeat center center/contain;
  transition: all 0.3s;
  transform: translateY(-50%);
}
@media only screen and (max-width: 1024px) {
  .btn._gray._arrow::after {
    right: 12px;
  }
}
.btn._gray:hover {
  color: #fff;
  background: #00aec4;
  border-color: #00aec4;
}
.btn._gray:hover::after {
  background-color: #fff;
}
@media only screen and (min-width: 1025px) {
  ._contact .btn._gray {
    min-height: 80px;
  }
}
@media only screen and (max-width: 1024px) {
  ._contact .btn._gray {
    padding-left: 12px;
  }
}
.btn._arrow span {
  position: relative;
  padding-right: 28px;
}
.btn._arrow span::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  height: 16px;
  content: "";
  background-color: #fff;
  mask: url("../img/icon_arrow.svg") no-repeat center center/contain;
  transition: all 0.3s;
  transform: translateY(-50%);
}
.btn._arrow:hover span::after {
  background-color: #00aec4;
}
@media only screen and (max-width: 1024px) {
  ._contact .btn._arrow span {
    padding-right: 12px;
  }
}
._contact .btn._arrow span::after {
  display: none;
}
.btn._ex {
  position: relative;
  padding-right: 64px;
}
@media only screen and (max-width: 1024px) {
  .btn._ex {
    padding-right: 46px;
  }
}
.btn._ex::after {
  position: absolute;
  top: 50%;
  right: 24px;
  width: 16px;
  height: 16px;
  content: "";
  background-color: #222;
  mask: url("../img/icon_ex.svg") no-repeat center center/contain;
  transition: all 0.3s;
  transform: translateY(-50%);
}
@media only screen and (max-width: 1024px) {
  .btn._ex::after {
    right: 12px;
    width: 12px;
    height: 12px;
  }
}
.btn._ex:hover::after {
  background-color: #fff;
}

._pdf {
  position: relative;
  padding-left: 22px;
  font: normal 14px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  text-decoration: underline;
}
@media only screen and (max-width: 1024px) {
  ._pdf {
    font-size: 10px;
  }
}
._pdf::before {
  position: absolute;
  top: 1px;
  left: 0;
  width: 16px;
  height: 16px;
  content: "";
  background-color: #222;
  mask: url("../img/icon_pdf.svg") no-repeat center center/contain;
}
@media only screen and (max-width: 1024px) {
  ._pdf::before {
    top: 0;
  }
}

.priceCheck {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e3e3e3;
}
.priceCheck__inner {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  margin-right: auto;
  margin-left: auto;
  background: #fff;
  border-radius: 8px;
}
@media only screen and (max-width: 1024px) {
  .priceCheck__inner {
    gap: 16px;
    padding: 24px 16px;
  }
}
.priceCheck__title {
  position: relative;
  padding-bottom: 23px;
  font: bold 24px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .priceCheck__title {
    padding-bottom: 16px;
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.priceCheck__title::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 64px;
  height: 2px;
  content: "";
  background: #00aec4;
  transform: translateX(-50%);
}
.priceCheck__btn {
  display: grid;
  gap: 8px;
}
@media only screen and (max-width: 1024px) {
  .priceCheck__btn {
    width: 100%;
  }
}

.column {
  display: grid;
}
@media only screen and (min-width: 1025px) {
  .column._2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 1025px) {
  .column._3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 1024px) {
  .column._3 {
    gap: 8px;
  }
}
@media only screen and (max-width: 1024px) {
  .column._noGap {
    gap: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .column._related {
    gap: 8px;
  }
}
.column._contact {
  margin-top: 60px;
}
@media only screen and (max-width: 1024px) {
  .column._contact {
    margin-top: 32px;
  }
}
.business__title + .column {
  margin-top: 40px;
}
@media only screen and (max-width: 1024px) {
  .business__title + .column {
    margin-top: 24px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 48px 32px 32px 48px;
}
.card._business, .card._related, .card._technology, .card._contact {
  padding: 32px;
}
@media only screen and (max-width: 1024px) {
  .card._business, .card._related, .card._technology, .card._contact {
    padding: 24px;
  }
}
.card._article {
  gap: 12px;
  padding: 24px;
}
@media only screen and (max-width: 1024px) {
  .card._article:not(:first-child) {
    border-top: none;
  }
}
@media only screen and (min-width: 1025px) {
  .card._technology {
    min-height: 369px;
  }
}
@media only screen and (max-width: 1024px) {
  .card._technology:not(:first-child) {
    border-top: none;
  }
}
@media only screen and (max-width: 1024px) {
  .card {
    gap: 16px;
    padding: 24px 24px 31px;
  }
}
._top .card {
  border-bottom: 1px solid #e3e3e3;
}
._top .card:nth-child(2) {
  border-left: 1px solid #e3e3e3;
}
@media only screen and (min-width: 1025px) {
  ._related .card {
    border: 1px solid #e3e3e3;
  }
  ._related .card:nth-child(2n) {
    border-left: none;
  }
}
@media only screen and (max-width: 1024px) {
  ._related .card {
    border: 1px solid #e3e3e3;
  }
}
@media only screen and (min-width: 1025px) {
  ._3 .card {
    border: 1px solid #e3e3e3;
  }
  ._3 .card:nth-child(3n+2), ._3 .card:nth-child(3n) {
    border-left: none;
  }
  ._3 .card:nth-child(n+4) {
    border-top: none;
  }
}
@media only screen and (max-width: 1024px) {
  ._3 .card {
    border: 1px solid #e3e3e3;
  }
}
.card__num {
  font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #00aec4;
}
@media only screen and (max-width: 1024px) {
  .card__num {
    font: bold 14px/14px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.card__title {
  position: relative;
  padding-bottom: 26px;
  font: bold 28px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  letter-spacing: 2.8px;
}
@media only screen and (min-width: 1025px) {
  ._business .card__title, ._technology .card__title {
    font: bold 24px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    letter-spacing: 2.4px;
  }
}
@media only screen and (min-width: 1025px) {
  ._technology .card__title {
    min-height: 130px;
  }
}
@media only screen and (min-width: 1025px) {
  ._related .card__title {
    font: bold 24px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
._contact .card__title {
  padding-right: 0;
  font: bold 18px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #666;
  letter-spacing: 0.9px;
}
@media only screen and (max-width: 1024px) {
  ._contact .card__title {
    padding-bottom: 0;
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    color: #00aec4;
  }
  ._contact .card__title::after {
    display: none;
  }
}
.card__title span {
  display: block;
  margin-top: 6px;
  font: bold 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  letter-spacing: 0.54px;
}
@media only screen and (max-width: 1024px) {
  .card__title span {
    font: normal 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
._business .card__title {
  flex-grow: 1;
}
@media only screen and (min-width: 1025px) {
  .card__title {
    padding-right: 16px;
  }
}
@media only screen and (max-width: 1024px) {
  .card__title {
    padding-bottom: 16px;
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    letter-spacing: 0.9px;
  }
}
.card__title::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 2px;
  content: "";
  background: #00aec4;
}
.card__text {
  flex-grow: 1;
  padding-right: 16px;
  color: #666;
}
.card__text a {
  color: #00aec4;
  text-decoration: underline;
}
._business .card__text {
  flex-grow: inherit;
}
._article .card__text {
  padding: 0;
  font: normal 14px/22px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  ._article .card__text {
    font: 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
._article .card__text:hover {
  text-decoration: underline;
}
._technology .card__text {
  padding: 0;
}
@media only screen and (max-width: 1024px) {
  .card__text {
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.card__img {
  position: relative;
}
.card__img::before {
  display: block;
  padding-top: 56.25%;
  content: "";
}
.card__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (min-width: 1025px) {
  .card__btn {
    text-align: right;
  }
}
@media only screen and (max-width: 1024px) {
  .card__btn {
    margin-top: 8px;
  }
}
._contact .card__btn {
  display: grid;
  gap: 24px;
  text-align: left;
}
@media only screen and (max-width: 1024px) {
  ._contact .card__btn {
    gap: 8px;
    margin-top: 0;
  }
}

.news__inner {
  position: relative;
  width: min(100% - 32px, 1200px);
  padding-top: 46px;
  padding-bottom: 84px;
  margin-inline: auto;
}
@media only screen and (max-width: 1024px) {
  .news__inner {
    width: min(100% - 24px, 1200px);
    padding-top: 32px;
    padding-bottom: 48px;
  }
  ._top .news__inner {
    display: flex;
    flex-direction: column;
  }
}
.news__top {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .news__top {
    display: contents;
  }
}
.news__contentWrap h2 {
  padding-bottom: 32px;
  margin-top: 16px;
  margin-bottom: 32px;
  font: bold 24px/40px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  border-bottom: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .news__contentWrap h2 {
    padding-bottom: 0;
    margin-bottom: 24px;
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    border: none;
  }
  ._newsDetail .news__contentWrap h2 {
    order: 2;
  }
}
@media only screen and (max-width: 1024px) {
  .news__contentWrap {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
  }
}
@media only screen and (max-width: 1024px) {
  .news__content {
    order: 3;
  }
}
.news__content .annotation {
  margin-top: 12px;
  font: normal 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #888;
}
@media only screen and (max-width: 1024px) {
  .news__content .annotation {
    font: normal 10px/16px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.news__content h3 {
  margin-top: 32px;
  font: bold 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .news__content h3 {
    margin-top: 24px;
    font: bold 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.news__content p {
  font: normal 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .news__content p {
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.news__content img {
  display: block;
  width: 100%;
  max-width: 960px;
  height: auto;
  margin-top: 48px;
  margin-bottom: 48px;
}
@media only screen and (max-width: 1024px) {
  .news__content img {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
.news__content .aligncenter img {
  margin-left: auto;
  margin-right: auto;
}
.news__content .wp-block-image:has(> .alignright)::after, .news__content .wp-block-image:has(> .alignleft)::after {
  content: "";
  display: block;
  clear: both;
}
.newsBox {
  display: grid;
  grid-template-columns: 200px auto;
}
@media only screen and (max-width: 1024px) {
  .newsBox {
    display: contents;
  }
}
.news__search {
  width: 100%;
  max-height: 40px;
  margin-left: auto;
}
@media only screen and (min-width: 1025px) {
  .news__search {
    max-width: 444px;
  }
}
@media only screen and (max-width: 1024px) {
  .news__search {
    width: 52%;
  }
}
.news__searchBox {
  display: flex;
  gap: 24px;
  width: 100%;
  margin-left: auto;
}
@media only screen and (max-width: 1024px) {
  .news__searchBox {
    gap: 12px;
    order: 1;
    padding: 12px 0;
    clip-path: inset(0 -100vmax);
    background: #fbfbfb;
    box-shadow: 0 0 0 100vmax #fbfbfb;
  }
}
.news__search form {
  position: relative;
  width: 100%;
}
.news__search form .box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 74px 10px 10px;
  background-image: linear-gradient(90deg, rgb(227, 227, 227) 86%, rgb(0, 174, 196) 64px);
  border-radius: 20px;
}
@media only screen and (max-width: 1024px) {
  .news__search form .box {
    padding: 10px 48px 10px 10px;
    background: #fff;
  }
}
.news__search form .submit {
  position: absolute;
  top: 50%;
  right: 24px;
  width: 12px;
  height: 12px;
  font-size: 0 !important;
  cursor: pointer;
  background: #00aec4;
  background-color: #fff;
  border: none;
  mask: url("../img/icon_search.svg") no-repeat center center/contain;
  transform: translateY(-50%);
}
@media only screen and (max-width: 1024px) {
  .news__search form .submit {
    width: 12px;
    height: 12px;
    background-color: #00aec4;
  }
}
.news__select {
  position: relative;
  max-height: 40px;
  background: #00aec4;
  border-radius: 20px;
}
@media only screen and (max-width: 1024px) {
  .news__select {
    width: 48%;
  }
}
.news__select::after {
  position: absolute;
  top: 50%;
  right: 12px;
  width: 16px;
  height: 16px;
  content: "";
  background-color: #fff;
  mask: url("../img/icon_arrow.svg") no-repeat center center/contain;
  transform: translateY(-50%) rotate(90deg);
}
.news__select select {
  width: 100%;
  height: 100%;
  padding: 10px 40px 10px 30px;
  font: normal 14px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif !important;
  color: #fff;
  text-align: center;
}
.news__select select option {
  color: #222;
}
@media only screen and (max-width: 1024px) {
  .news__select select {
    padding: 5px 40px 5px 20px;
    font: normal 12px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif !important;
  }
}
.news__sectionTitle {
  font: bold 24px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  letter-spacing: 2.4px;
}
@media only screen and (min-width: 1025px) {
  .news__sectionTitle {
    padding-left: 16px;
  }
}
@media only screen and (max-width: 1024px) {
  .news__sectionTitle {
    position: relative;
    order: 1;
    padding-bottom: 16px;
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
  .news__sectionTitle::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 64px;
    height: 2px;
    content: "";
    background: #00aec4;
  }
}
@media only screen and (min-width: 1025px) {
  .news__btn {
    margin-left: auto;
  }
}
@media only screen and (max-width: 1024px) {
  .news__btn {
    order: 4;
    margin-top: 24px;
  }
}
.newsList {
  display: grid;
  gap: 32px;
  margin-top: 48px;
}
@media only screen and (max-width: 1024px) {
  .newsList {
    gap: 24px;
    margin-top: 32px;
  }
  ._searchResult .newsList {
    order: 3;
  }
}
@media only screen and (min-width: 1025px) {
  .newsList__item {
    padding-right: 8px;
    padding-left: 8px;
  }
}
.news__label {
  display: flex;
  gap: 24px;
  align-items: center;
  color: #888;
}
._article .news__label {
  gap: 12px;
  font: normal 14px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  ._article .news__label {
    font: normal 10px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
@media only screen and (max-width: 1024px) {
  .news__label {
    gap: 12px;
  }
  ._newsDetail .news__label {
    order: 1;
  }
}
.news__date {
  color: #888;
}
@media only screen and (max-width: 1024px) {
  .news__date {
    font: normal 10px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.news__cat {
  padding-left: 24px;
  color: #888;
  border-left: 1px solid #e3e3e3;
}
._article .news__cat {
  padding-left: 12px;
}
@media only screen and (max-width: 1024px) {
  .news__cat {
    padding-left: 12px;
    font: normal 10px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.news__title {
  margin-top: 16px;
}
@media only screen and (max-width: 1024px) {
  .news__title {
    margin-top: 8px;
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.news__title a {
  color: #666;
}
.news__title a:hover {
  text-decoration: underline;
}

.snsList {
  display: flex;
  gap: 24px;
  align-items: center;
}
@media only screen and (min-width: 1025px) {
  .snsList {
    justify-content: right;
    margin-right: 5px;
  }
}
@media only screen and (max-width: 1024px) {
  .snsList {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e3e3e3;
  }
  ._newsDetail .snsList {
    order: 3;
  }
}

.searchResult {
  margin-top: 60px;
  font: bold 24px/40px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
.searchResult._noHit {
  padding-bottom: 300px;
  font-weight: normal;
  color: #666;
}
@media only screen and (max-width: 1024px) {
  .searchResult._noHit {
    padding-bottom: 120px;
  }
}
@media only screen and (max-width: 1024px) {
  .searchResult {
    order: 3;
    margin-top: 32px;
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.searchResult .keyword {
  color: #00aec4;
}
.searchResult .num {
  color: #00aec4;
}

._link {
  transition: all 0.3s ease 0s;
}
._link:hover {
  opacity: 0.7;
}

.breadcrumb {
  display: flex;
  align-items: center;
  padding: 13.5px 32px;
  overflow: hidden;
  background: #fbfbfb;
}
@media only screen and (max-width: 1024px) {
  .breadcrumb {
    display: none;
  }
}
.breadcrumb__item {
  font: normal 14px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  letter-spacing: 1.4px;
}
.breadcrumb__item:not(:first-child) {
  position: relative;
  padding-left: 40px;
}
.breadcrumb__item:not(:first-child)::before {
  position: absolute;
  top: 50%;
  left: 12px;
  width: 16px;
  height: 16px;
  content: "";
  background-color: #e3e3e3;
  mask: url("../img/icon_arrow.svg") no-repeat center center/contain;
  transform: translateY(-50%);
}
.breadcrumb__item a {
  color: #888;
  white-space: nowrap;
}
.breadcrumb__item span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.section__inner {
  width: min(100% - 32px, 960px);
  padding-top: 60px;
  padding-bottom: 120px;
  margin-inline: auto;
}
.section__inner._narrow {
  padding-top: 32px;
}
.section__inner._wide {
  width: min(100% - 32px, 1200px);
}
@media only screen and (max-width: 1024px) {
  .section__inner._wide {
    width: min(100% - 48px, 1200px);
  }
}
@media only screen and (max-width: 1024px) {
  .section__inner {
    width: min(100% - 48px, 960px);
    padding-top: 32px;
    padding-bottom: 48px;
  }
  .news .section__inner {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
}
.section__title {
  position: relative;
  padding-bottom: 24px;
  font: bold 28px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #222;
  letter-spacing: 2.8px;
}
@media only screen and (max-width: 1024px) {
  .section__title._gpm {
    font-size: 16px;
    padding-right: 20px;
  }
}
.section__title::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 2px;
  content: "";
  background: #00aec4;
}
@media only screen and (max-width: 1024px) {
  .section__title {
    padding-bottom: 16px;
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
  .news .section__title {
    order: 2;
    margin-top: 32px;
  }
}

.pagination {
  display: flex;
  gap: 17px;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  margin-top: 60px;
  border-top: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .pagination {
    margin-top: 32px;
  }
  .news .pagination {
    order: 4;
  }
}
.pagination__item a,
.pagination__item span {
  font: normal 14px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  letter-spacing: 1.4px;
}
.pagination__item._current {
  color: #00aec4;
}
.pagination__item._last a, .pagination__item._first a, .pagination__item._next a, .pagination__item._prev a {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
}
.pagination__item._last a::after, .pagination__item._first a::after, .pagination__item._next a::after, .pagination__item._prev a::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #888;
  mask: url("../img/icon_arrow.svg") no-repeat center center/contain;
  transform: translate(-50%, -50%);
}
.pagination__item._first a::after, .pagination__item._prev a::after {
  transform: translate(-50%, -50%) rotate(180deg);
}

.js-tabs {
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 1025px) {
  .js-tabs {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 1024px) {
  .js-tabs {
    display: contents;
  }
  ._about .js-tabs {
    display: none;
  }
  .news .js-tabs {
    order: 3;
  }
}
.js-tabs-list {
  display: grid;
  gap: 1px;
}
@media only screen and (min-width: 1025px) {
  .js-tabs-list {
    grid-template-columns: repeat(4, 1fr);
  }
  .js-tabs-list._2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 1024px) {
  .js-tabs-list {
    position: absolute;
    top: 32px;
    right: 0;
    order: 2;
    width: 200px;
    height: 18px;
  }
}
.js-tabs-listitem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}
.js-tabs-listitem a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  color: #fff;
}
@media only screen and (min-width: 1025px) {
  .js-tabs-listitem {
    padding: 10px 5px;
    color: #fff;
    background-color: #00aec4;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    border-radius: 4px 4px 0 0;
  }
  .js-tabs-listitem::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 64px;
    height: 2px;
    content: "";
    background: #00aec4;
    opacity: 0;
    transition: opacity 0.3s;
    transform: translateX(-50%);
  }
}
@media only screen and (max-width: 1024px) {
  .js-tabs-listitem {
    justify-content: right;
    padding-right: 28px;
    font: normal 12px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
  .js-tabs-listitem::after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 16px;
    height: 16px;
    content: "";
    background-color: #666;
    mask: url("../img/icon_arrow.svg") no-repeat center center/contain;
    transform: translateY(-50%) rotate(90deg);
  }
}
@media only screen and (min-width: 1025px) {
  .js-tabs-listitem:hover {
    color: #00aec4;
    background: #fff;
    border-color: #00aec4;
  }
  .js-tabs-listitem:hover a {
    color: #00aec4;
  }
  .js-tabs-listitem._active {
    color: #00aec4;
    background: #fff;
    border-color: #e3e3e3;
  }
  .js-tabs-listitem._active a {
    color: #00aec4;
  }
  .js-tabs-listitem._active::after {
    opacity: 1;
  }
}
.js-tabs-listitem:has(a) {
  padding: 0;
}
.js-tabs-contents {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .js-tabs-contents {
    order: 3;
  }
}
.js-tabs-content {
  display: none;
  width: 100%;
}
.js-tabs-content._active {
  display: block;
}
@media only screen and (max-width: 1024px) {
  .js-tabs .js-tab-select {
    direction: rtl;
    font: 12px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif !important;
    color: #222;
  }
  .js-tabs .js-tab-select-wrap {
    position: absolute;
    top: 35px;
    right: 0;
    order: 2;
    padding-right: 28px;
  }
  ._page-news .js-tabs .js-tab-select-wrap {
    top: 144px;
    right: 24px;
  }
  .js-tabs .js-tab-select-wrap::after {
    position: absolute;
    top: 3px;
    right: 0;
    width: 16px;
    height: 16px;
    content: "";
    background-color: #222;
    mask: url("../img/icon_arrow.svg") no-repeat center center/contain;
    transform: rotate(90deg);
  }
  .js-tabs .js-tab-select-wrap._contact {
    display: none !important;
  }
  .js-tabs .js-tab-select option {
    color: #222;
  }
}

.drawer {
  position: fixed;
  top: 48px;
  right: 0;
  z-index: 20;
  width: 100%;
  height: 100dvh;
  overflow-y: scroll;
  background-color: #fff;
  transition: all 0.3s;
  transform: translateX(100%);
}
@media only screen and (min-width: 1025px) {
  .drawerWrap {
    display: none;
  }
}
.drawerList {
  width: 100%;
  padding-bottom: 140px;
}
.drawerList__item {
  width: 100%;
  font-weight: bold;
  border-bottom: 1px solid #fbfbfb;
}
.drawerList__item a {
  display: block;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
}
.drawerList__item:has(.js-accordion) {
  padding: 0;
}
._active .drawer {
  transform: translateX(0);
}
.drawerButton {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
  width: 66px;
  height: 48px;
}
.drawerButton__top {
  position: absolute;
  top: 36%;
  left: 50%;
  width: 24px;
  height: 2px;
  background-color: #000;
  transition: all 0.3s;
  transform: translateX(-50%);
}
._active .drawerButton__top {
  top: 62%;
  transform: rotate(45deg) translateX(-50%);
}
.drawerButton__med {
  position: absolute;
  left: 50%;
  width: 24px;
  height: 2px;
  background-color: #000;
  transition: all 0.3s;
  transform: translateX(-50%);
}
._active .drawerButton__med {
  opacity: 0;
}
.drawerButton__bottom {
  position: absolute;
  top: 65%;
  left: 50%;
  width: 24px;
  height: 2px;
  background-color: #000;
  transition: all 0.3s;
  transform: translateX(-50%);
}
._active .drawerButton__bottom {
  top: 28%;
  transform: rotate(-45deg) translateX(-50%);
}

body._drawerOpen {
  overflow: hidden;
}

.js-accordion {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.js-accordionList {
  width: 100%;
  background-color: #fff;
}
.js-accordionList__item {
  width: 100%;
}
.js-accordionList__item:not(:first-child) {
  margin-top: 10px;
}
.js-accordionList__item__switch {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  cursor: pointer;
  border-bottom: 1px solid #e3e3e3;
}
.js-accordionList__item__inner {
  width: 100%;
  overflow: hidden;
  transition: all 0.5s;
}
.js-accordionList__item__content {
  width: 100%;
  padding-bottom: 16px;
  background-color: #fbfbfb;
}
.js-accordionList__item__content ul li:not(:first-child) {
  border-top: 1px solid #e3e3e3;
}
.js-accordionList__item__content ul li a {
  display: block;
  padding: 16px 0 16px 48px;
  font: normal 12px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #666;
}

.js-accordionList__item__switch:not([data-is-open=false]) {
  color: #00aec4;
}

.business__title {
  margin-top: 48px;
  font: bold 18px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .business__title {
    margin-top: 16px;
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.business__title._en {
  margin-top: 24px;
  font: 500 24px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #00aec4;
  letter-spacing: 2.4px;
}
@media only screen and (max-width: 1024px) {
  .business__title._en {
    margin-top: 16px;
    font: 500 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.business__title._en span {
  display: block;
  margin-top: 6px;
  font: 500 18px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  letter-spacing: 0.54px;
}
@media only screen and (max-width: 1024px) {
  .business__title._en span {
    font: normal 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.business__text {
  margin-top: 24px;
  margin-bottom: 48px;
  font: normal 16px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .business__text {
    margin-top: 16px;
    margin-bottom: 32px;
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.business__energyTitle {
  font: bold 24px/40px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  letter-spacing: 1.2px;
  margin-top: 120px;
  color: #00aec4;
}
@media only screen and (max-width: 1024px) {
  .business__energyTitle {
    margin-top: 60px;
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    letter-spacing: 0.9px;
  }
}
.business__img {
  margin-top: 32px;
  margin-bottom: 120px;
}
@media only screen and (max-width: 1024px) {
  .business__img {
    margin-top: 12px;
    margin-bottom: 60px;
  }
}

.related {
  padding-top: 60px;
  margin-top: 60px;
  border-top: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .related {
    padding-top: 32px;
    margin-top: 32px;
  }
}
.related__title {
  margin-bottom: 40px;
  font: bold 18px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .related__title {
    margin-bottom: 24px;
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}

.about__inner {
  padding-top: 60px;
}
@media only screen and (max-width: 1024px) {
  .about__inner {
    padding-top: 32px;
  }
}
.about__img {
  max-width: 420px;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (max-width: 1024px) {
  .about__img {
    margin-top: 28px;
  }
}

.reason__title {
  padding-top: 60px;
  margin-top: 120px;
  font: bold 18px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  text-align: center;
  border-top: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .reason__title {
    padding-top: 32px;
    margin-top: 60px;
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.reason__subTitle {
  margin-top: 48px;
  font: bold 24px/40px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #00aec4;
}
@media only screen and (max-width: 1024px) {
  .reason__subTitle {
    margin-top: 32px;
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.reason__text {
  margin-top: 24px;
  font: normal 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #444;
}
@media only screen and (max-width: 1024px) {
  .reason__text {
    margin-top: 16px;
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}

.corporateTitle {
  font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #00aec4;
  text-align: center;
}
.corporateInfo dl {
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  font: normal 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #666;
  border-bottom: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .corporateInfo dl {
    display: block;
    font: normal 12px/28px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.corporateInfo dl dt {
  font-weight: bold;
}
@media only screen and (min-width: 1025px) {
  .corporateInfo dl dt {
    width: 23%;
  }
}
@media only screen and (min-width: 1025px) {
  .corporateInfo dl dd {
    width: 77%;
  }
}
@media only screen and (max-width: 1024px) {
  .corporateInfo dl dd {
    margin-top: 12px;
  }
}

.officerTitle {
  font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #00aec4;
  text-align: center;
}
.officerList {
  display: grid;
  gap: 48px;
}
@media only screen and (max-width: 1024px) {
  .officerList {
    gap: 32px;
    margin-top: 24px;
  }
}
.officerList__item {
  padding-bottom: 24px;
  border-bottom: 1px solid #e3e3e3;
}
.officerList__item:has(.img) {
  display: grid;
  grid-template-columns: 22.5% auto;
  gap: 24px;
}
@media only screen and (max-width: 1024px) {
  .officerList__item:has(.img) {
    grid-template-rows: auto;
    grid-template-columns: 33% 1fr;
    align-items: center;
  }
  .officerList__item:has(.img) .content {
    display: contents;
  }
  .officerList__item:has(.img) .img {
    grid-area: 1/1/2/2;
  }
  .officerList__item:has(.img) .post_and_name {
    grid-area: 1/2/2/3;
  }
  .officerList__item:has(.img) .text {
    grid-area: 2/1/3/3;
    margin-top: 0;
  }
}
.officerList__item .post {
  font: bold 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #444;
}
@media only screen and (max-width: 1024px) {
  .officerList__item .post {
    font: bold 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.officerList__item .name {
  margin-top: 8px;
  font: bold 24px/40px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .officerList__item .name {
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.officerList__item .text {
  margin-top: 24px;
  font: normal 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #666;
}
@media only screen and (max-width: 1024px) {
  .officerList__item .text {
    margin-top: 16px;
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.accessTitle {
  font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #00aec4;
  text-align: center;
}
.accessList {
  display: grid;
  gap: 60px;
  margin-top: 24px;
}
@media only screen and (max-width: 1024px) {
  .accessList {
    gap: 32px;
  }
}
.accessList__item {
  display: grid;
  gap: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .accessList__item {
    gap: 16px;
    padding-bottom: 16px;
  }
}
.accessList__item .name {
  font: bold 24px/40px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #00aec4;
}
@media only screen and (max-width: 1024px) {
  .accessList__item .name {
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.accessList__item .map {
  position: relative;
  width: 100%;
}
.accessList__item .map::before {
  display: block;
  padding-top: 33.3%;
  content: "";
}
@media only screen and (max-width: 1024px) {
  .accessList__item .map::before {
    padding-top: 58%;
  }
}
.accessList__item .map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.accessList__item .address {
  font: normal 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .accessList__item .address {
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.accessList__item .address span {
  font: normal 12px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #888;
}
@media only screen and (max-width: 1024px) {
  .accessList__item .address span {
    font: normal 10px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}

.recruit__message {
  margin: 120px auto;
}
@media only screen and (min-width: 1025px) {
  .recruit__message {
    max-width: 697px;
  }
}
@media only screen and (max-width: 1024px) {
  .recruit__message {
    margin: 60px auto;
    text-align: center;
  }
}
.recruit__title {
  margin-top: 24px;
  font: bold 24px/40px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .recruit__title {
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.recruit__title._primary {
  margin-top: 32px;
  color: #00aec4;
}
@media only screen and (min-width: 1025px) {
  .recruit__title._primary {
    text-align: center;
  }
}
.recruit__titleSub {
  font: bold 18px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (min-width: 1025px) {
  .recruit__titleSub {
    text-align: center;
  }
}
@media only screen and (max-width: 1024px) {
  .recruit__titleSub {
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.recruit__titleSub._divide {
  padding-top: 60px;
  margin-top: 60px;
  border-top: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .recruit__titleSub._divide {
    padding-top: 32px;
    margin-top: 32px;
  }
}
.recruit__text {
  font: normal 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .recruit__text {
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.recruit__text._center {
  margin-top: 24px;
}
@media only screen and (min-width: 1025px) {
  .recruit__text._center {
    text-align: center;
  }
}
@media only screen and (max-width: 1024px) {
  .recruit__text._center {
    margin-top: 16px;
  }
}
.recruitList {
  padding-bottom: 60px;
  margin-top: 60px;
  margin-bottom: 60px;
  border-bottom: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .recruitList {
    display: grid;
    gap: 32px;
    padding-bottom: 32px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
.recruitList__item {
  display: grid;
  gap: 32px;
  align-items: center;
}
@media only screen and (min-width: 1025px) {
  .recruitList__item {
    grid-template-columns: 33.3% auto;
  }
  .recruitList__item:not(:first-child) {
    margin-top: -47px;
  }
}
@media only screen and (max-width: 1024px) {
  .recruitList__item {
    gap: 16px;
  }
}
@media only screen and (max-width: 1024px) {
  .recruitList__img {
    max-width: 180px;
    margin: 0 auto;
  }
}
.recruit__detail {
  margin-top: 48px;
  font: normal 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .recruit__detail {
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.recruit__detail .primary {
  display: block;
  font-weight: bold;
  color: #00aec4;
}
.recruit__detail strong {
  display: block;
  font-weight: bold;
}
.recruit__detail a {
  color: #00aec4;
  text-decoration: underline;
}

.technology__title {
  margin-top: 24px;
  font: bold 24px/40px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #00aec4;
  letter-spacing: 1.2px;
}
@media only screen and (max-width: 1024px) {
  .technology__title {
    margin-top: 16px;
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.technology__text {
  margin-top: 48px;
  font: bold 18px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .technology__text {
    margin-top: 16px;
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.technology__img {
  margin-top: 12px;
  margin-bottom: 48px;
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .technology__img {
    margin-bottom: 16px;
  }
}
.technology__example {
  max-width: 630px;
  margin: 48px auto;
}
@media only screen and (max-width: 1024px) {
  .technology__example {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
.technologyList__item {
  padding-top: 60px;
  padding-bottom: 24px;
  border-top: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .technologyList__item {
    padding-top: 32px;
  }
}
.technologyList__title {
  font: bold 24px/40px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .technologyList__title {
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.technologyList__title._donation {
  font: bold 18px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .technologyList__title._donation {
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.technologyList__content {
  margin-top: 24px;
}
@media only screen and (max-width: 1024px) {
  .technologyList__content {
    margin-top: 16px;
  }
}
.technologyList__content._01 {
  display: grid;
  gap: 24px;
}
@media only screen and (min-width: 1025px) {
  .technologyList__content._01 {
    grid-template-columns: 22.5% auto;
  }
}
@media only screen and (max-width: 1024px) {
  .technologyList__content._01 {
    gap: 16px;
  }
}
.technologyList__content._02 {
  display: grid;
  gap: 24px;
}
@media only screen and (min-width: 1025px) {
  .technologyList__content._02 {
    grid-template-columns: 50% auto;
  }
}
@media only screen and (max-width: 1024px) {
  .technologyList__content._02 {
    gap: 16px;
  }
}
.technologyList__content:not(:has(.technologyList__img)) {
  margin-top: 48px;
}
@media only screen and (max-width: 1024px) {
  .technologyList__content:not(:has(.technologyList__img)) {
    margin-top: 16px;
  }
}
.technologyList__content:not(:has(.technologyList__img)) .technologyList__text {
  color: #444;
}
.technologyList__content a {
  display: block;
  font-variant: normal 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #888;
  text-decoration: underline;
}
.technologyList__content a:first-child {
  margin-top: 12px;
}
.technologyList__content strong {
  display: block;
  font-weight: bold;
}
@media only screen and (max-width: 1024px) {
  .technologyList__img {
    margin-right: auto;
    margin-left: auto;
  }
  ._01 .technologyList__img {
    max-width: 120px;
  }
  ._02 .technologyList__img {
    max-width: 212px;
  }
}
.technologyList__text {
  font: normal 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #666;
}
@media only screen and (max-width: 1024px) {
  .technologyList__text {
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}

.strong {
  position: relative;
  padding-left: 1em;
}
.strong::before {
  position: absolute;
  top: 11px;
  left: 0;
  width: 9px;
  height: 9px;
  content: "";
  background: #444;
}
@media only screen and (max-width: 1024px) {
  .strong::before {
    top: 5px;
  }
}

._rule {
  font: normal 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #444;
}
@media only screen and (max-width: 1024px) {
  ._rule {
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
._rule p._right {
  text-align: right;
}
._rule p a._link {
  color: #00aec4;
  text-decoration: underline;
}
._rule .rule__text {
  margin-top: 48px;
  color: #444;
}
@media only screen and (max-width: 1024px) {
  ._rule .rule__text {
    margin-top: 32px;
  }
}
._rule .rule__detail {
  margin-top: 48px;
  margin-bottom: 60px;
  text-align: right;
}
@media only screen and (max-width: 1024px) {
  ._rule .rule__detail {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
._rule .rule__contact {
  margin-top: 60px;
  margin-bottom: 24px;
}
@media only screen and (max-width: 1024px) {
  ._rule .rule__contact {
    margin-top: 32px;
  }
}
._rule .rule__contact span {
  display: block;
  margin-top: 12px;
  font: normal 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #888;
}
@media only screen and (max-width: 1024px) {
  ._rule .rule__contact span {
    font: normal 10px/16px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
._rule .ruleBox {
  padding: 24px;
  margin: 24px 0;
  background: #fbfbfb;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleBox {
    padding: 16px;
    margin: 12px 0;
    border-radius: 8px;
  }
}
._rule .ruleBox p {
  margin-bottom: 12px;
  font: bold 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #222;
  letter-spacing: 1.6px;
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleBox p {
    margin-bottom: 8px;
  }
}
._rule .ruleBox p:not(:first-child) {
  margin-top: 24px;
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleBox p:not(:first-child) {
    margin-top: 8px;
  }
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleBox p {
    font: bold 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
._rule .ruleBox ol,
._rule .ruleBox ul {
  color: #222;
}
._rule .ruleBox ol li,
._rule .ruleBox ul li {
  position: relative;
  padding-left: 2em;
  letter-spacing: 1.6px;
}
._rule .ruleBox ol li:not(:first-child),
._rule .ruleBox ul li:not(:first-child) {
  margin-top: 12px;
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleBox ol li:not(:first-child),
  ._rule .ruleBox ul li:not(:first-child) {
    margin-top: 8px;
  }
}
._rule .ruleBox ul li::before {
  position: absolute;
  top: 0;
  left: 0;
  font: normal 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  content: "・";
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleBox ul li::before {
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
._rule .ruleBox ol {
  counter-reset: count 0;
}
._rule .ruleBox ol li {
  text-indent: -1.5em;
}
._rule .ruleBox ol li::before {
  content: counter(count) ". ";
  counter-increment: count 1;
}
._rule .rule__dl {
  margin-top: 48px;
  margin-bottom: 32px;
  border: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  ._rule .rule__dl {
    margin-top: 32px;
    margin-bottom: 24px;
  }
}
._rule .rule__dl dl {
  display: flex;
}
._rule .rule__dl dl:not(:first-child) {
  border-top: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  ._rule .rule__dl dl {
    font: normal 10px/16px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
._rule .rule__dl dl dt,
._rule .rule__dl dl dd {
  padding: 24px;
}
@media only screen and (max-width: 1024px) {
  ._rule .rule__dl dl dt,
  ._rule .rule__dl dl dd {
    padding: 12px;
  }
}
._rule .rule__dl dl dt {
  width: 45%;
  font-weight: bold;
  color: #00aec4;
}
@media only screen and (max-width: 1024px) {
  ._rule .rule__dl dl dt {
    width: 37%;
  }
}
._rule .rule__dl dl dd {
  width: 55%;
  border-left: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  ._rule .rule__dl dl dd {
    width: 63%;
  }
}
._rule .ruleList {
  color: #222;
}
._rule .ruleList ._pdf {
  display: block;
  margin-top: 12px;
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleList ._pdf {
    margin-top: 8px;
  }
}
._rule .ruleList__item {
  padding-top: 48px;
  padding-bottom: 60px;
  border-bottom: 1px solid #e3e3e3;
}
._rule .ruleList__item:not(:first-child) {
  padding-top: 60px;
  border-top: 1px solid "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleList__item:not(:first-child) {
    padding-top: 32px;
  }
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleList__item {
    padding-top: 32px;
    padding-bottom: 24px;
  }
}
._rule .ruleList__title {
  margin-bottom: 24px;
  font: bold 24px/40px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #00aec4;
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleList__title {
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
._rule .ruleList__subTitle {
  margin-bottom: 24px;
  font: bold 18px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
._rule .ruleList__subTitle:not(:first-child) {
  margin-top: 24px;
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleList__subTitle {
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
._rule .ruleList__subText {
  margin-bottom: 6px;
  font: bold 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleList__subText {
    font: bold 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
._rule .ruleList__text {
  position: relative;
  padding-left: 16px;
  margin-top: 24px;
  margin-bottom: 12px;
  font: bold 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleList__text {
    padding-left: 12px;
    font: bold 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
._rule .ruleList__text a {
  text-decoration: underline;
}
._rule .ruleList__text::before {
  position: absolute;
  top: 10px;
  left: 0;
  width: 12px;
  height: 12px;
  content: "";
  background: #00aec4;
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleList__text::before {
    top: 6px;
    width: 8px;
    height: 8px;
  }
}
@media only screen and (max-width: 1024px) {
  ._rule .ruleList__column:not(:first-child) {
    padding-top: 24px;
    margin-top: 32px;
    border-top: 1px solid #e3e3e3;
  }
}
._rule .ruleList__columnWrap {
  display: grid;
}
@media only screen and (min-width: 1025px) {
  ._rule .ruleList__columnWrap {
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }
}

.privacyList {
  display: grid;
  gap: 60px 32px;
  padding-top: 60px;
  margin-top: 60px;
  border-top: 1px solid #e3e3e3;
}
@media only screen and (min-width: 1025px) {
  .privacyList {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 1024px) {
  .privacyList {
    gap: 32px;
    padding-top: 32px;
    margin-top: 32px;
  }
}
.privacyList__item {
  padding-bottom: 60px;
  border-bottom: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .privacyList__item {
    padding-bottom: 32px;
  }
}
.privacyList__item__title {
  margin-bottom: 24px;
  font: bold 24px/40px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #00aec4;
  letter-spacing: 1.2px;
}
@media only screen and (max-width: 1024px) {
  .privacyList__item__title {
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.page-sustainability {
  overflow: hidden;
  font: normal 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #444;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability {
    font: normal 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
@media only screen and (min-width: 1025px) {
  .page-sustainability {
    letter-spacing: 1.6px;
  }
}
.page-sustainability ._noMargin {
  margin: 0 !important;
}
.page-sustainability ._spacer {
  margin-top: 24px;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability ._spacer {
    margin-top: 12px;
  }
}
.page-sustainability .mt60 {
  margin-top: 60px !important;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability .mt60 {
    margin-top: 32px !important;
  }
}
.page-sustainability .recruit__sub {
  margin-top: 60px;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability .recruit__sub {
    margin-top: 0;
  }
}
.page-sustainability .divide {
  padding-top: 60px;
  margin-top: 60px;
  border-top: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability .divide {
    padding-top: 32px;
    margin-top: 32px;
  }
}
.page-sustainability img {
  display: block;
  margin-top: 24px;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability img {
    margin-top: 12px;
  }
}
.page-sustainability p._margin {
  margin-top: 24px;
}
.page-sustainability p a {
  color: #00aec4;
  text-decoration: underline;
}
.page-sustainability .heading03 {
  padding-top: 120px;
  margin-top: 120px;
  margin-bottom: 60px;
  font: bold 18px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #222;
  text-align: center;
  letter-spacing: 1.8px;
  border-top: 1px solid #e3e3e3;
}
.page-sustainability .heading03._first {
  margin-top: 0;
  border: none;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability .heading03 {
    padding-top: 60px;
    margin-top: 60px;
    margin-bottom: 32px;
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    letter-spacing: 0.7px;
  }
}
.page-sustainability .heading04 {
  margin-top: 60px;
  margin-bottom: 24px;
  font: bold 24px/40px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #00aec4;
  letter-spacing: 1.2px;
}
.page-sustainability .heading04._black {
  color: #222;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability .heading04 {
    margin-top: 32px;
    margin-bottom: 16px;
    font: bold 18px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    letter-spacing: 0.9px;
  }
}
.page-sustainability .heading05 {
  margin-top: 32px;
  margin-bottom: 12px;
  font: bold 18px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  letter-spacing: 1.8px;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability .heading05 {
    margin-top: 24px;
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    letter-spacing: 0.7px;
  }
}
.page-sustainability .heading06 {
  margin-top: 24px;
  margin-bottom: 24px;
  font: bold 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  letter-spacing: 1.6px;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability .heading06 {
    font: bold 12px/20px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    letter-spacing: 0.6px;
  }
}
.page-sustainability .annotation {
  padding-left: 1em;
  margin-top: 24px;
  font: normal 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #888;
  text-indent: -1em;
  letter-spacing: 0.7px;
}
.page-sustainability .annotation + .annotation {
  margin-top: 0;
}
.page-sustainability .annotation._noIndent {
  padding-left: 0;
  text-indent: 0;
}
.page-sustainability .annotation._asterisk {
  padding-left: 3em;
  text-indent: -3.4em;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability .annotation {
    margin-top: 12px;
    font: normal 10px/16px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    letter-spacing: 0.5px;
  }
}
.page-sustainability .annotation a {
  display: inline;
  color: #888;
  text-decoration: underline;
}
.page-sustainability .link {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  color: #00aec4;
  text-decoration: underline;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability .link {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
.page-sustainability .tcfd__imgWrap {
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability .tcfd__imgWrap {
    gap: 8px;
    margin-top: 12px;
  }
}
.page-sustainability .tcfd__img:nth-child(1) {
  max-width: 295px;
}
.page-sustainability .tcfd__img:nth-child(2) {
  max-width: 247px;
}
.page-sustainability .tcfd__img:nth-child(3) {
  max-width: 114px;
}
.page-sustainability .materialityTable {
  overflow-x: auto;
}
@media only screen and (max-width: 600px) {
  .page-sustainability .materialityTable {
    position: relative;
    width: 100vw;
    margin: 0 calc(50% - 50vw) 0 0;
  }
}
.page-sustainability .materialityTable table {
  position: relative;
  width: 100%;
  min-width: 600px;
  border: 1px solid #e3e3e3;
}
@media only screen and (max-width: 600px) {
  .page-sustainability .materialityTable table {
    display: block;
    padding-right: 48px;
    border: none;
  }
  .page-sustainability .materialityTable table dl:last-child {
    border-bottom: 1px solid #e3e3e3;
  }
  .page-sustainability .materialityTable table::after {
    position: absolute;
    top: 0;
    left: 551px;
    width: 1px;
    height: 100%;
    content: "";
    background: #e3e3e3;
  }
}
.page-sustainability .materialityTable table,
.page-sustainability .materialityTable td {
  color: #666;
  letter-spacing: 0.8px;
  border-collapse: collapse;
}
.page-sustainability .materialityTable tr._top td {
  padding: 14px 24px;
  font: bold 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #fff;
  text-align: center;
  background: #888;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability .materialityTable tr._top td {
    font: bold 10px/16px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.page-sustainability .materialityTable td {
  width: 33.3333%;
  min-height: 80px;
  padding: 16px 24px;
  font: normal 16px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  border: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .page-sustainability .materialityTable td {
    padding: 9px 12px;
    font: bold 10px/16px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.page-sustainability .materialityTable td p {
  padding-left: 1em;
  text-indent: -1em;
}
.page-sustainability .materialityTable td._green, .page-sustainability .materialityTable td._red, .page-sustainability .materialityTable td._blue {
  font-weight: bold;
  line-height: 2;
}
.page-sustainability .materialityTable td._green {
  color: #78ad00;
}
.page-sustainability .materialityTable td._red {
  color: #fa6478;
}
.page-sustainability .materialityTable td._blue {
  color: #0080c8;
}

.table {
  width: 100%;
  min-width: 600px;
  border: 1px solid #e3e3e3;
}
.tableWrap {
  margin-top: 24px;
  margin-bottom: 24px;
  overflow-x: auto;
  letter-spacing: 0.8px;
}
@media only screen and (max-width: 600px) {
  .tableWrap {
    position: relative;
    width: 100vw;
    margin: 24px calc(50% - 50vw) 0 0;
  }
}
@media only screen and (max-width: 600px) {
  .table {
    padding-right: 48px;
    border-top: none;
    border-right: none;
    border-bottom: none;
  }
  .table dl:last-child {
    border-bottom: 1px solid #e3e3e3;
  }
  .table::after {
    position: absolute;
    top: 0;
    left: 551px;
    width: 1px;
    height: 100%;
    content: "";
    background: #e3e3e3;
  }
}
.table._center dd {
  justify-content: center;
  text-align: center;
}
.table._row2 dt {
  width: 33%;
}
@media only screen and (max-width: 1024px) {
  .table._row2 dt {
    width: 25%;
  }
}
.table._row2 dd {
  width: 67%;
}
@media only screen and (max-width: 1024px) {
  .table._row2 dd {
    width: 75%;
  }
}
.table._row3 dt,
.table._row3 dd {
  width: 33.3%;
}
.table._gpm dl {
  background: #fbfbfb;
}
.table._gpm dl._top {
  background: #00aec4;
}
.table._gpm dl._top dd {
  padding: 7px;
  color: #fff;
  justify-content: center;
}
.table._gpm dl dd {
  text-align: left;
  padding: 10px 15px;
  color: #222;
  justify-content: left;
}
.table._gpm dl dd._num {
  justify-content: right;
}
.table._row4 dt,
.table._row4 dd {
  width: 25%;
}
.table._row5 dt {
  width: 24.5%;
}
.table._row5 dd:nth-child(2) {
  width: 20.5%;
}
.table._row5 dd:nth-child(3) {
  width: 20.5%;
}
.table._row5 dd:nth-child(4) {
  width: 21.5%;
}
.table._row5 dd:nth-child(5) {
  width: 13%;
}
.table dl {
  display: flex;
  min-height: 80px;
}
.table dl:not(:first-child) {
  border-top: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .table dl {
    min-height: 50px;
  }
}
.table dl._top {
  min-height: auto;
  background: #888;
}
.table dl._top dt,
.table dl._top dd {
  padding: 9px 24px;
  font: bold 16px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  color: #fff;
}
@media only screen and (max-width: 1024px) {
  .table dl._top dt,
  .table dl._top dd {
    min-height: 40px;
    padding: 4px 12px;
    font: bold 10px/16px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
  .table dl._top dt:has(span),
  .table dl._top dd:has(span) {
    padding: 4px 0;
  }
}
.table dl dt,
.table dl dd {
  display: flex;
  align-items: center;
  padding: 8px 24px;
  color: #666;
}
@media only screen and (max-width: 1024px) {
  .table dl dt,
  .table dl dd {
    padding: 8px 12px;
    font: normal 10px/16px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.table dl dt {
  font-weight: bold;
}
.table dl dt._right {
  justify-content: right;
}
.table dl dd {
  border-left: 1px solid #e3e3e3;
}
.table dl dd:has(span) {
  flex-direction: column;
}
.table dl dd span {
  display: block;
  font: normal 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .table dl dd span {
    font: normal 10px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}

.grayBox {
  padding: 24px;
  margin-top: 24px;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
}
@media only screen and (max-width: 1024px) {
  .grayBox {
    display: grid;
    gap: 8px;
    padding: 16px;
    margin-top: 12px;
  }
}
.grayBox + .grayBox {
  margin-top: 16px;
}
@media only screen and (max-width: 1024px) {
  .grayBox + .grayBox {
    margin-top: 8px;
  }
}
.grayBox._narrowLs {
  letter-spacing: 0.8px;
}
@media only screen and (max-width: 1024px) {
  .grayBox._narrowLs {
    letter-spacing: 0.6px;
  }
}
@media only screen and (max-width: 1024px) {
  .grayBox {
    border-radius: 8px;
  }
}
.grayBox .title {
  font-weight: bold;
  color: #00aec4;
}
.grayBox .title._black {
  color: #444;
}
.grayBox .text._bold {
  font-weight: bold;
}
.grayBox .text span {
  display: block;
  padding-left: 1em;
  text-indent: -1em;
}
@media only screen and (max-width: 1024px) {
  .grayBox .text span:not(:first-child) {
    margin-top: 8px;
  }
}

.workBox {
  padding-bottom: 24px;
  border-bottom: 1px solid #e3e3e3;
}
.workBox .title {
  margin-top: 32px;
  font: bold 18px/32px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .workBox .title {
    margin-top: 24px;
    font: bold 14px/24px "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.workBox .content {
  margin-top: 12px;
}
.workBox .content:has(.img) {
  display: grid;
  gap: 24px;
}
@media only screen and (min-width: 1025px) {
  .workBox .content:has(.img) {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 1024px) {
  .workBox .content:has(.img) {
    gap: 12px;
  }
}
.workBox .content .img {
  position: relative;
}
.workBox .content .img::before {
  display: block;
  padding-top: 50%;
  content: "";
}
.workBox .content .img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  object-fit: cover;
}
.workBox .content .text {
  margin: 0;
}

.composition {
  padding-top: 60px;
  margin-top: 60px;
  border-top: 1px solid #e3e3e3;
}
.composition__title {
  font: bold 14px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  padding-left: 20px;
  position: relative;
  margin-top: 24px;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1024px) {
  .composition__title {
    margin-top: 12px;
    font: bold 14px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    padding-left: 16px;
  }
}
.composition__title::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  background: #00aec4;
  top: 50%;
  border-radius: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media only screen and (max-width: 1024px) {
  .composition__title::before {
    width: 12px;
    height: 12px;
  }
}
.composition__text {
  font: normal 16px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  margin-top: 24px;
  margin-bottom: 24px;
}
@media only screen and (max-width: 1024px) {
  .composition__text {
    font: normal 12px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
.composition__img {
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 1024px) {
  .composition__img {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
.composition__annotation {
  padding-left: 1em;
  text-indent: -1em;
  font: normal 12px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.composition__annotation span {
  display: block;
  padding-left: 1em;
}
@media only screen and (max-width: 1024px) {
  .composition__annotation {
    font: normal 10px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.composition__box {
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 20px;
  border: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .composition__box {
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 15px;
    font: normal 12px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.composition__table table, .composition__table td, .composition__table th {
  border: 1px solid #e3e3e3;
  border-collapse: collapse;
}
@media only screen and (max-width: 1024px) {
  .composition__table table, .composition__table td, .composition__table th {
    font: normal 12px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  }
}
.composition__table tr {
  background: #fbfbfb;
}
.composition__table tr._top {
  font-weight: bold;
  background: #00aec4;
  color: #fff;
  text-align: center;
}
.composition__table tr._top td {
  padding: 7px;
}
.composition__table td {
  width: 33.333%;
}
.composition__table td._num {
  text-align: right;
}
@media only screen and (max-width: 1024px) {
  .composition__table td {
    width: 18%;
  }
  .composition__table td:nth-child(2) {
    width: 64%;
  }
}
.composition__table td, .composition__table th {
  padding: 10px 15px;
}
@media only screen and (max-width: 1024px) {
  .composition__table td, .composition__table th {
    padding: 10px;
  }
}
.modalButton {
  text-decoration: underline;
  display: flex;
  margin-left: auto;
  text-decoration-color: #222;
}
.modalButton span {
  display: inline-block;
  padding-left: 20px;
  position: relative;
  color: #222;
}
@media only screen and (max-width: 1024px) {
  .modalButton span {
    font: normal 12px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
    padding-left: 16px;
  }
}
.modalButton span::before {
  position: absolute;
  content: "";
  background-color: #00aec4;
  width: 14px;
  height: 18px;
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  left: 0;
  top: 3px;
}
@media only screen and (max-width: 1024px) {
  .modalButton span::before {
    top: 3px;
    width: 10px;
    height: 12px;
  }
}
.modalContent {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  margin: auto;
  width: 100%;
  height: 80%;
  max-width: 900px;
  padding: 60px 25px;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  overflow-y: scroll;
  border-radius: 70px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.modalContent::-webkit-scrollbar {
  display: none;
}
.modalContentOpen {
  visibility: visible;
  opacity: 1;
}
@media only screen and (max-width: 1024px) {
  .modalContent {
    border-radius: 50px;
    width: 90%;
    padding: 40px 10px;
  }
}
.modalOverflow {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 25px;
}
@media only screen and (max-width: 1024px) {
  .modalOverflow {
    padding: 0 10px;
  }
}
.modalClose {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
}
.modalClose:hover {
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .modalClose {
    top: 3px;
    width: 18px;
    height: 18px;
  }
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 200vw;
  height: 200vh;
  background-color: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}
.modalOverlayOpen {
  visibility: visible;
  opacity: 1;
}

/* -----------------------------------------------
* Page エントリーポイント
-------------------------------------------------- */