@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 88px;
  background: #fff;
  transition: all 0.3s;

  &._topPage {
    height: 128px;

    @include sp {
      height: 66px;

      .drawer {
        top: 66px;
      }

      .header__logo {
        width: 144px;
      }
    }

    .drawerButton {
      height: 66px;
    }

    .drawerWrap {
      &._active {
        .drawerButton__bottom {
          top: 37%;
        }
      }
    }
  }

  &._scrolled {
    height: 88px;

    @include sp {
      height: 48px;

      .header__logo {
        width: 108px;
      }

      .drawerButton {
        height: 48px;
      }

      .drawer {
        top: 48px;
      }

      .drawerWrap {
        &._active {
          .drawerButton__bottom {
            top: 28%;
          }
        }
      }
    }
  }

  @include sp {
    height: 48px;
    border-bottom: 1px solid $border;
  }

  &__inner {
    align-items: center;
    height: 100%;
    padding-right: 32px;
    padding-left: 32px;

    @include pc {
      display: grid;
      grid-template-columns: 172px auto;
    }

    @include sp {
      padding-right: 24px;
      padding-left: 24px;
    }
  }

  &__logo {
    position: relative;
    z-index: 100;

    @include sp {
      display: flex;
      align-items: center;
      width: 108px;
      height: 100%;
      padding-top: 5px;
      transition: all 0.3s;
    }
  }

  &Link {
    position: relative;
    display: flex;
    gap: 26px;
    align-items: center;
    height: 100%;
    margin-left: auto;

    @include sp {
      display: none;
    }

    @include pc- {
      gap: 10px;
    }

    &__item {
      position: static;
      display: flex;
      align-items: center;
      height: 100%;
      transition: all 0.3s;

      @include pc- {
        font: bold 14px/1.5 $font-ja;
      }

      &:hover {
        .subMenu {
          pointer-events: auto;
          visibility: visible;
          opacity: 1;
        }
      }

      &Link {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        font-weight: bold;

        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          content: "";
          background: $primary;
          opacity: 0;
          transition: opacity 0.3s;
        }

        &._current {
          &::after {
            opacity: 1;
          }
        }
      }

      &:hover {
        .headerLink__itemLink {
          color: $primary;

          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  .subMenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 32px 32px 48px 0;
    clip-path: inset(0 -100vmax);
    pointer-events: none;
    visibility: hidden;
    background: rgba(102, 102, 102, 0.99);
    box-shadow: 0 0 0 100vmax rgba(102, 102, 102, 0.99);
    opacity: 0;
    transition: all 0.3s;

    &__inner {
      display: grid;
      gap: 16px;
      max-width: 288px;
    }

    &__item {
      &Title {
        margin-bottom: 32px;
        font: bold 16px/24px $font-ja;
        color: #fff;
      }

      &Link {
        position: relative;
        display: block;
        font: normal 16px/1.5 $font-ja;
        color: #e3e3e3;
        transition: all 0.3s;

        &:not(:first-child) {
          margin-top: 16px;
        }

        &::after {
          position: absolute;
          top: 50%;
          right: 0;
          width: 16px;
          height: 16px;
          content: "";
          background-color: #e3e3e3;
          mask: url("../img/icon_arrow.svg") no-repeat center center / contain;
          transition: all 0.3s;
          transform: translateY(-50%);
        }

        &:hover {
          opacity: 0.5;

          &::after {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.header {
  &._about {
    .headerLink__itemLink {
      &._about {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &._business {
    .headerLink__itemLink {
      &._business {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &._technology {
    .headerLink__itemLink {
      &._technology {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &._news {
    .headerLink__itemLink {
      &._news {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &._sustainability {
    .headerLink__itemLink {
      &._sustainability {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &._ir {
    .headerLink__itemLink {
      &._ir {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &._recruit {
    .headerLink__itemLink {
      &._recruit {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &._contact {
    .headerLink__itemLink {
      &._contact {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
