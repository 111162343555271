@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

// ドロワーメニュー
.drawer {
  position: fixed;
  top: 48px;
  right: 0;
  z-index: 20;
  width: 100%;
  height: 100dvh;
  overflow-y: scroll;
  background-color: #fff;
  transition: all 0.3s;
  transform: translateX(100%);

  &Wrap {
    @include pc {
      display: none;
    }
  }

  &List {
    width: 100%;
    padding-bottom: 140px;

    &__item {
      width: 100%;
      font-weight: bold;
      border-bottom: 1px solid #fbfbfb;

      a {
        display: block;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 24px;
      }

      &:has(.js-accordion) {
        padding: 0;
      }
    }
  }

  ._active & {
    transform: translateX(0);
  }

  &Button {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 30;
    width: 66px;
    height: 48px;

    &__top {
      position: absolute;
      top: 36%;
      left: 50%;
      width: 24px;
      height: 2px;
      background-color: #000;
      transition: all 0.3s;
      transform: translateX(-50%);

      ._active & {
        top: 62%;
        transform: rotate(45deg) translateX(-50%);
      }
    }

    &__med {
      position: absolute;
      left: 50%;
      width: 24px;
      height: 2px;
      background-color: #000;
      transition: all 0.3s;
      transform: translateX(-50%);

      ._active & {
        opacity: 0;
      }
    }

    &__bottom {
      position: absolute;
      top: 65%;
      left: 50%;
      width: 24px;
      height: 2px;
      background-color: #000;
      transition: all 0.3s;
      transform: translateX(-50%);

      ._active & {
        top: 28%;
        transform: rotate(-45deg) translateX(-50%);
      }
    }
  }
}

body {
  &._drawerOpen {
    overflow: hidden;
  }
}
