@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.business {
  &__title {
    margin-top: 48px;
    font: bold 18px/32px $font-ja;

    @include sp {
      margin-top: 16px;
      font: bold 14px/24px $font-ja;
    }

    &._en {
      margin-top: 24px;
      font: 500 24px/32px $font-ja;
      color: $primary;
      letter-spacing: 2.4px;

      @include sp {
        margin-top: 16px;
        font: 500 18px/24px $font-ja;
      }

      span {
        display: block;
        margin-top: 6px;
        font: 500 18px/32px $font-ja;
        letter-spacing: 0.54px;

        @include sp {
          font: normal 14px/24px $font-ja;
        }
      }
    }
  }

  &__text {
    margin-top: 24px;
    margin-bottom: 48px;
    font: normal 16px/24px $font-ja;

    @include sp {
      margin-top: 16px;
      margin-bottom: 32px;
      font: normal 12px/20px $font-ja;
    }
  }

  &__energyTitle{
    font: bold 24px/40px $font-ja;
    letter-spacing: 1.2px;
    margin-top: 120px;
    color: $primary;
    @include sp {
      margin-top: 60px;
      font: bold 18px/24px $font-ja;
      letter-spacing: 0.9px;
    }
  }

  &__img {
    margin-top: 32px;
    margin-bottom: 120px;

    @include sp {
      margin-top: 12px;
      margin-bottom: 60px;
    }
  }
}
